import detectMobileOs, { isMobileBrowserAgent } from './detectMobileOS'
import LocalStorage from './localStorage'
import Location from './location'
import REGEX from './regex'
import SessionStorage from './sessionStorage'
import StorageSSRFallback from './storageSSRFallback'
import Tracker from './tracker'
import getIntlLocaleRegionTag from './intl'
import GoogleOptimize from './googleOptimize'
import { mapToV2Program } from './objectTransformers'
import VWO from './vwo'

export {
    detectMobileOs,
    isMobileBrowserAgent,
    getIntlLocaleRegionTag,
    LocalStorage,
    Location,
    REGEX,
    SessionStorage,
    StorageSSRFallback,
    Tracker,
    GoogleOptimize,
    mapToV2Program,
    VWO,
}
