import Footer from './footer'
import FooterListGroup from './component/footer-list-group/footer-list-group'
import FooterList from './component/footer-list/footer-list'
import FooterListItem from './component/footer-list-item/footer-list-item'
import FooterLogo from './component/footer-logo/footer-logo'
import FooterSection from './component/footer-section/footer-section'
import SocialIcon from './component/social-icon/social-icon'

export {
    Footer,
    FooterListGroup,
    FooterList,
    FooterListItem,
    FooterLogo,
    FooterSection,
    SocialIcon
}

export default Footer
