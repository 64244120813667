import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { useIntl } from 'react-intl'
import Icon from '../icon'
import * as styleVars from '../../style-vars'
import detectMobileOs from '../../utils/detectMobileOS'
import useMediaQuery from '../../hooks/useMediaQuery'
import appStoreSvgDe from '../../svg/appstore-de.svg'
import googlePlaySvgDe from '../../svg/google-play-de.svg'
import appStoreSvgEn from '../../svg/appstore-en.svg'
import googlePlaySvgEn from '../../svg/google-play-en.svg'

import style from './app-download-button-group.module.scss'

// eslint-disable-next-line
const APPLE_STORE_LINK =
    'https://itunes.apple.com/us/app/gymondo-fitness-programme/id1011796416?ls=1&mt=8'
const GOOGLE_STORE_LINK =
    'https://play.google.com/store/apps/details?id=de.gymondo.app.gymondo'

const MOBILE_OSES = {
    ANDROID: 'Android',
    IOS: 'iOS',
}

const BADGE_TO_LOCALE_MAP = {
    en: {
        [MOBILE_OSES.ANDROID]: googlePlaySvgEn,
        [MOBILE_OSES.IOS]: appStoreSvgEn,
    },
    de: {
        [MOBILE_OSES.ANDROID]: googlePlaySvgDe,
        [MOBILE_OSES.IOS]: appStoreSvgDe,
    },
}

const renderStoreButton = (icon, href, svgClassName, onClick, storeName) => (
    <a
        className={cn(style.anchor, svgClassName)}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => onClick(storeName)}
    >
        <Icon data={icon} />
    </a>
)

const AppDownloadButtonGroup = ({
    className,
    svgClassName,
    alwaysShow,
    onClick,
    showAll,
}) => {
    const intl = useIntl()
    const [mobileOs, setMobileOs] = useState(null)
    const badges = BADGE_TO_LOCALE_MAP[intl.locale] || BADGE_TO_LOCALE_MAP.en
    const matches = useMediaQuery(styleVars.mediaMinSm)
    useEffect(() => {
        setMobileOs(detectMobileOs())
    }, [])
    if (!alwaysShow && matches && mobileOs === 'unknown') {
        return null
    }

    return (
        <div className={cn(style.wrapper, className)}>
            {(mobileOs === 'unknown' ||
                mobileOs === MOBILE_OSES.IOS ||
                showAll) &&
                renderStoreButton(
                    badges[MOBILE_OSES.IOS],
                    APPLE_STORE_LINK,
                    svgClassName,
                    onClick,
                    MOBILE_OSES.IOS
                )}
            {(mobileOs === 'unknown' ||
                mobileOs === MOBILE_OSES.ANDROID ||
                showAll) &&
                renderStoreButton(
                    badges[MOBILE_OSES.ANDROID],
                    GOOGLE_STORE_LINK,
                    svgClassName,
                    onClick,
                    MOBILE_OSES.ANDROID
                )}
        </div>
    )
}

AppDownloadButtonGroup.propTypes = {
    className: PropTypes.string,
    svgClassName: PropTypes.string,
    alwaysShow: PropTypes.bool,
    onClick: PropTypes.func,
    showAll: PropTypes.bool,
}

AppDownloadButtonGroup.defaultProps = {
    className: null,
    svgClassName: null,
    alwaysShow: false,
    onClick: () => {},
    showAll: false,
}

export default AppDownloadButtonGroup
