enum QUERY_PARAMS {
    SIGNUP_ACTION = 'action',
    SHOW_LOGIN_LEGACY = 'loginNeeded',
    SHOW_LOGIN = 'login',
    CHANNEL = 'channel',
    CHANNEL_ID = 'channel_id',
    PROGRAM_ID = 'program_id',
    VOUCHER = 'voucher',
    PREVENTION_VOUCHER = 'preventionVoucher',
    REFERRAL_ID = 'refId',
    RESET_PASSWORD_CODE = 'code',
    FORWARD_URL = 'fw',
    SELECTED_PAYMENT_METHOD = 'paymentMethod',
    RESULT_CODE = 'resultCode',
    REFUSAL_REASON = 'refusalReason',
    SOVENDUS_TOKEN = 'sovReqToken',
    ZANOX_TOKEN_ZANPID = 'zanpid',
    ZANOX_TOKEN_AWC = 'awc',
    USERNAME = 'u',
    PSP_REFERENCE = 'pspReference',
    SHOPPER_REFERENCE = 'shopperReference',
    SESSION_ID = 'sessionId',
    REDIRECT_RESULT = 'redirectResult',
    UTM_SOURCE = 'utm_source',
    UTM_MEDIUM = 'utm_medium',
    GCLID = 'gclid',
}

export default QUERY_PARAMS
