import de from '../lang/de'
import en from '../lang/en'

/**
 * This file set the default values for the video player. If nothing is defined,
 * it will have the default player from Gymondo.
 */
const videoFrameworkOptions = {
    html5: {
        hls: {
            overrideNative: true,
            enableLowInitialPlaylist: true,
        },
    },
    flash: {
        swf: './dist/asset/video-js.swf',
    },
    controlBar: {
        children: [],
    },
    fluid: true,
    controls: true,
    preload: 'auto',
    playsinline: true,
    nativeControlsForTouch: false,
    languages: {
        de,
        en,
    },
}

const options = {
    audioLanguage: 'de',
    playerLanguage: 'de',
    playerItems: [
        'previousExercise',
        'playToggle',
        'nextExercise',
        'annotations',
        'exerciseTopBar',
        'cuePoints',
        'gradients',
        'markers',
    ],
    controlBarItems: [
        'progressControl',
        'languageSelector',
        'qualitySelector',
        'audioSelector',
        'fullscreenToggle',
    ],
    audioSelector: ['musicVolume', 'musicEmbed', 'spotify'],
    initial: {
        quality: null,
        audioCategory: 'music',
        musicVolume: 0.8,
    },
}

export default {
    options,
    videoFrameworkOptions,
}
