import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { FormattedMessage } from 'react-intl'

import style from './pie-chart.module.scss'

const PieChart = ({ className, data, viewBoxWidth }) => {
    const FONT_SIZE = '6px'

    const renderText = (x, y, text, labelFill) => (
        <text
            fontSize={FONT_SIZE}
            fill={labelFill}
            x={x}
            y={y}
            className="guillon-demi"
            textAnchor="middle"
            alignmentBaseline="middle"
        >
            {text}
        </text>
    )

    const getSector = () => {
        const center = viewBoxWidth / 2

        return (
            <>
                <ellipse
                    fill={data[0].color}
                    cx={center}
                    cy={center}
                    rx={center}
                    ry={center}
                />
                {
                    renderText(center, center, '100%', 'white')
                }
            </>
        )
    }

    const getSectors = () => {
        const total = Math.ceil(data.reduce((prev, current) => current.value + prev, 0))
        const outSpace = data.find(d => d.value < 5) ? 6 : 0
        const center = viewBoxWidth / 2 - outSpace - 2
        let startAngle = 0
        let endAngle = 0

        return (
            data.map((d, i) => {
                const angle = 360 * d.value / total
                const largeArc = (d.value / total) <= 0.5 ? 0 : 1

                startAngle = endAngle
                endAngle = startAngle + angle

                const x1 = Math.round(center + center * Math.cos(Math.PI * startAngle / 180))
                const y1 = Math.round(center + center * Math.sin(Math.PI * startAngle / 180))

                const x2 = Math.round(center + center * Math.cos(Math.PI * endAngle / 180))
                const y2 = Math.round(center + center * Math.sin(Math.PI * endAngle / 180))

                // eslint-disable-next-line
                const dPath = `M${center},${center} L${x1},${y1} A${center},${center} 0 ${largeArc},1 ${x2},${y2} z`

                const labelAngle = startAngle + (endAngle - startAngle) / 2
                const greater5 = d.value >= 5
                const centerLabel = greater5 ? center * 0.75 : center * 1.15
                const labelFill = greater5 ? 'white' : d.color
                const xLabel = Math.round(
                    center + centerLabel * Math.cos(Math.PI * labelAngle / 180)
                )
                const yLabel = Math.round(
                    center + centerLabel * Math.sin(Math.PI * labelAngle / 180)
                ) + 1

                return (
                    <React.Fragment
                        key={`sector-frag-${i}`}
                    >
                        <path
                            key={`sector${i}`}
                            d={dPath}
                            fill={d.color}
                            stroke="#fff"
                            strokeWidth={1}
                            strokeMiterlimit={0}
                        />
                        {
                            renderText(xLabel, yLabel, `${d.value}%`, labelFill)
                        }
                    </React.Fragment>
                )
            })
        )
    }

    const hasOutsideLabel = data.filter(item => item.value < 5).length > 0
    const extraSpace = hasOutsideLabel ? 15 : 0

    return (
        <div className={style.pieWrapper}>
            <div className={cn(style.chart, 'guillon')}>
                <svg
                    className={className}
                    viewBox={`0 0 ${viewBoxWidth + extraSpace} ${viewBoxWidth + extraSpace}`}
                >
                    <g>
                        {
                            data.length === 1
                                ? getSector()
                                : getSectors()
                        }
                    </g>
                </svg>
            </div>
            <div className={style.chartDescription}>
                {
                    data.map((element, i) => (
                        <div className={style.itemWrapper} key={i}>
                            <span className={style.item} style={{ background: element.color }} />
                            <span className={style.itemText}>
                                <FormattedMessage id={element.label} />
                            </span>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

PieChart.propTypes = {
    className: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number,
        color: PropTypes.string,
        label: PropTypes.string
    })).isRequired,
    viewBoxWidth: PropTypes.number,
}

PieChart.defaultProps = {
    className: '',
    viewBoxWidth: 96,
}

export default PieChart
