function responseInterceptor(axios, processResponse, onError = err => Promise.reject(err)) {
    axios.interceptors.response.use(
        response => processResponse(response),
        onError
    )

    return axios
}

export default responseInterceptor
