enum SESSION_STORAGE_KEYS {
    CHANNEL_ID = 'channelId',
    CHANNEL = 'channel',
    RECOMMENDED_PROGRAM_ID = 'recommended',
    CAMPAIGN_VOUCHER = 'campaign.voucher',
    EXPLICIT_VOUCHER = 'signup.explicitVoucher',
    SIGNUP_CAMPAIGN = 'signup.campaign',
    SIGNUP_VOUCHER = 'signup.voucher',
    UTM_SOURCE = 'optimize_utm_source',
    UTM_MEDIUM = 'optimize_utm_medium',
    GCLID = 'optimize_gclid',
}

export default SESSION_STORAGE_KEYS
