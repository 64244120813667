import _ from 'lodash'

/**
 * This is a Videojs implementation for a Button.
 *
 * @param {*} player 
 * @param {*} methods 
 */
const MenuItemFactory = (player, methods = {}) => {
    const MenuItemComponent = player.getFrameworkComponent('MenuItem')

    class PlayerMenuItem extends MenuItemComponent {
        constructor(...params) {
            super(...params)
            if (_.isFunction(methods.constructor)) {
                methods.constructor.call(this, ...params)
            }
        }

        handleClick(...params) {
            if (_.isFunction(methods.handleClick)) {
                return methods.handleClick.call(this, ...params)
            }

            return super.handleClick(...params)
        }

        buildCSSClass() {
            if (_.isFunction(methods.buildCSSClass)) {
                return methods.buildCSSClass.call(this)
            }

            return super.buildCSSClass()
        }

        getHtmlEl() {
            return this.el()
        }

        addClass(classToAdd) {
            super.addClass(classToAdd)
        }
    }

    return PlayerMenuItem
}

export default MenuItemFactory
