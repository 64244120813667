import cn from 'classnames'
import { useIntl } from 'react-intl'
import React, { useCallback } from 'react'
import { createSvgIcon } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuItem'
import ListSubheader from '@mui/material/ListSubheader'

import TextField from '../text-field'
import { SelectProps } from './types'

import style from './select.module.scss'

const ChevronIcon: React.FC = createSvgIcon(<path d="M11.998 13.39 7.462 8.757a.842.842 0 0 0-1.211 0 .892.892 0 0 0 0 1.24l5.14 5.248a.844.844 0 0 0 1.183.026L17.75 10a.884.884 0 0 0 .25-.62.884.884 0 0 0-.25-.62.842.842 0 0 0-1.211 0l-4.54 4.63Z" />, 'Chevron')

const Select: React.FC<SelectProps> = ({ className, id, label, error, classes, placeholder, FormHelperTextProps, helperText, fullWidth, StartSlot, size, renderValue, onChange, disabled, name: controlName, options, children, ...restProps }) => {
    const intl = useIntl()

    const handleChange = useCallback((event) => {
        const eventValue = event?.target?.value

        if (Array.isArray(eventValue) && eventValue.length === 2 && eventValue[0] === '') {
            eventValue.shift()
        }

        onChange?.(eventValue)

        return eventValue
    }, [onChange])

    const getValue = useCallback((value) => (Array.isArray(value) && value.length === 0) ? [''] : value, [])

    return (
        <TextField
            select
            className={cn(style.select, className)}
            classes={classes}
            id={id}
            name={controlName}
            label={label}
            fullWidth={fullWidth}
            helperText={helperText}
            FormHelperTextProps={FormHelperTextProps}
            error={error}
            StartSlot={StartSlot}
            size={size}
            disabled={disabled}
            getValue={getValue}
            onChange={handleChange}
            SelectProps={{
                displayEmpty: true,
                IconComponent: ChevronIcon,
                ...restProps,
            }}
        >
            <MenuItem value="" className={style.placeholder}>{intl.formatMessage({ id: placeholder })}</MenuItem>

            {!children && options?.map(({ name, value, className, ...restProps }) => (
                <MenuItem
                    className={cn('guillon', className)}
                    value={value}
                    key={value}
                    {...restProps}
                >
                    {typeof name === 'string' ? intl.formatMessage({ id: name }) : name}
                </MenuItem>
            ))}

            {children}
        </TextField>
    )
}

export default Select
export { MenuItem, MenuList, ListSubheader }
