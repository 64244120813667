import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import loadingIcon from '../../svg/loading-icon.svg'
import Icon from '../icon/icon'

const BASE_BUTTON_CLASS = 'btn-gym'

const BASE_BUTTON_CLASS_FLEX = 'btn--flex'

const TYPE_MODIFIERS_MAP = {
    chip: 'btn--chip',
    facebook: 'btn-gym--facebook',
    google: 'btn-gym--google',
    paypal: 'btn-gym--paypal',
    apple: 'btn-gym--apple',
    amazon: 'btn-gym--amazon',
}

const SIZE_MODIFIERS_MAP = {
    small: 'btn--small',
    medium: 'btn--medium',
    big: 'btn--big',
}

const DISABLED_CLASS_NAME = 'btn--disabled'
const INVERSED_CLASS_NAME = 'btn--inverse'
const FIXED_CLASS_NAME = 'btn--fixed'
const LOADING_SVG_CLASS_NAME = 'btn-gym__svg--loading'
const SVG_CLASS_NAME = 'btn-gym__svg'
const FLUID_CLASS_NAME = 'btn--fluid'
const BORDERED_CLASS_NAME = 'btn--border'
const BG_CLASS_NAME = 'btn-gym__bg'
const CONTENT_CLASS_NAME = 'btn-gym__content'

/**
 * Button
 */
const Button = ({
    type = 'default',
    variant = null,
    size = 'regular',
    onClick = () => {},
    className = '',
    inversed = false,
    bordered = false,
    fixed = false,
    loading = false,
    fluid = false,
    flex = true,
    disabled = false,
    htmlType = 'button',
    children,
    icon = null,
    ...props
}) => {
    const combinedClassNames = cn(
        BASE_BUTTON_CLASS,
        flex && BASE_BUTTON_CLASS_FLEX,
        SIZE_MODIFIERS_MAP[size],
        variant && `${BASE_BUTTON_CLASS}--${variant}`,
        TYPE_MODIFIERS_MAP[type],
        disabled && DISABLED_CLASS_NAME,
        inversed && INVERSED_CLASS_NAME,
        bordered && BORDERED_CLASS_NAME,
        fixed && FIXED_CLASS_NAME,
        fluid && FLUID_CLASS_NAME,
        className
    )

    const withIcon = ['paypal', 'apple', 'amazon']
    const hasIcon = withIcon.includes(type)

    return (
        // eslint-disable-next-line react/button-has-type
        <button
            disabled={disabled}
            className={combinedClassNames}
            type={htmlType}
            size={size}
            onClick={onClick}
            {...props}
        >
            {loading && (
                <Icon data={loadingIcon} className={LOADING_SVG_CLASS_NAME} />
            )}
            <div className={cn(CONTENT_CLASS_NAME)}>
                {icon && <Icon data={icon} className={SVG_CLASS_NAME} />}
                {hasIcon && <span className={BG_CLASS_NAME} />}
                {children}
            </div>
        </button>
    )
}

Button.propTypes = {
    /**
     * Button type
     */
    type: PropTypes.oneOf([
        'default',
        'chip',
        'facebook',
        'google',
        'paypal',
        'apple',
        'amazon',
    ]),
    /**
     * The variant of the Button
     */
    variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
    /**
     * The size of the Button
     */
    size: PropTypes.oneOf(['regular', 'small', 'medium', 'big']),
    /**
     * Disable the Button
     */
    disabled: PropTypes.bool,
    /**
     * Invert the Button
     */
    inversed: PropTypes.bool,
    /**
     * Apply border
     */
    bordered: PropTypes.bool,
    /**
     * Fix the Button at the bottom of the page
     */
    fixed: PropTypes.bool,
    /**
     * Display the loading animation
     */
    loading: PropTypes.bool,
    /**
     * Make the Button fluid
     */
    fluid: PropTypes.bool,
    /**
     * Add display:flex; to button
     */
    flex: PropTypes.bool,
    /**
     * Imported svg to display as Button Icon
     */
    icon: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.string,
        PropTypes.elementType,
    ]),
    /**
     * Button css class
     */
    className: PropTypes.string,
    /**
     * Html type of the Button
     */
    htmlType: PropTypes.oneOf(['button', 'submit', 'reset']),
    /**
     * Gets called when the user clicks on the Button
     *
     * @param {SyntheticEvent} event The react `SyntheticEvent`
     * @param {Object} allProps All props of this Button
     */
    onClick: PropTypes.func,
    /**
     * Button label
     */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
}

export default Button
