import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Icon from 'components/icon'
import HeaderContext from '../header-context'

import gymondoLogoBlack from '../../../svg/logo-black.svg'

const GymLogoWhite = (
    <Icon
        data={gymondoLogoBlack}
        className={cn('header-logo__svg', 'gym-logo__svg--white')}
    />
)
const GymLogoCoral = (
    <Icon
        data={gymondoLogoBlack}
        className={cn('header-logo__svg', 'gym-logo__svg--ci')}
    />
)

/**
 * Logo for Header
 */
const HeaderLogo = ({
    href = null,
    alt = '',
    initialComponent = GymLogoWhite,
    secondaryComponent = GymLogoCoral,
    mobile = false,
    className = null,
}) => {
    const { fixed } = useContext(HeaderContext)

    const Component = fixed || mobile ? secondaryComponent : initialComponent

    if (href) {
        return (
            <a href={href} alt={alt} className={cn('gym-logo', className)}>
                {Component}
            </a>
        )
    }

    return <div className={cn('gym-logo', className)}>{Component}</div>
}

HeaderLogo.propTypes = {
    /**
     * Pass a href here to wrap the component in an anchor tag,
     * if no href is provided, component will be wrappent in a div
     */
    href: PropTypes.string,
    /**
     * Alt propery for anchor
     */
    alt: PropTypes.string,
    /**
     * Component that will be rendered in initial state of Header
     */
    initialComponent: PropTypes.node,
    /**
     * Component that will be rendered in fixed (scroll) state of Header
     */
    secondaryComponent: PropTypes.node,
    /**
     * Logo belongs to the mobile header
     */
    mobile: PropTypes.bool,
    /**
     * CSS className applied to the wrapping element
     */
    className: PropTypes.string,
}

export default HeaderLogo
