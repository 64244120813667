import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import InputNote from '../common/InputNote'
import InputLabel from '../common/InputLabel'
import Icon from '../icon/icon'

export const BASE_CLASS_NAME = 'input'
export const ERROR_CLASS_NAME = `${BASE_CLASS_NAME}--invalid`
export const VALID_CLASS_NAME = `${BASE_CLASS_NAME}--valid`
export const ICON_CLASS_NAME = `${BASE_CLASS_NAME}--icon`
export const DISABLED_CLASS_NAME = `${BASE_CLASS_NAME}--disabled`
export const INPUT_VARIANT_CLASSNAME_MAP = {
    primary: `${BASE_CLASS_NAME}--primary`,
    secondary: `${BASE_CLASS_NAME}--secondary`,
}
export const TEXTAREA_CLASS_NAME = `${BASE_CLASS_NAME}--textarea`

export const SIZE_CLASS_NAME_MAP = { small: `${BASE_CLASS_NAME}--small` }

/**
 * Custom Input
 * to be used with Formik Field component
 * https://jaredpalmer.com/formik/docs/api/field
 */
const Input = ({
    className,
    labelClassName,
    variant,
    size,
    field,
    form: { touched, errors },
    label,
    withValidIcon,
    multiline,
    icon,
    ...rest
}) => {
    const fieldTouched = touched[field.name]
    const hasError = fieldTouched && errors[field.name]
    const Component = multiline ? 'textarea' : 'input'

    return (
        <div className={cn(className, 'input-container')}>
            {label && (
                <InputLabel className={labelClassName} htmlFor={field.name}>
                    {label}
                </InputLabel>
            )}
            <div className={`${BASE_CLASS_NAME}-wrapper`}>
                {icon && !multiline && (
                    <div className={`${BASE_CLASS_NAME}-icon-wrapper`}>
                        <Icon
                            data={icon}
                            className={`${BASE_CLASS_NAME}-icon`}
                        />
                    </div>
                )}
                <Component
                    className={cn(
                        BASE_CLASS_NAME,
                        INPUT_VARIANT_CLASSNAME_MAP[variant],
                        SIZE_CLASS_NAME_MAP[size],
                        hasError && ERROR_CLASS_NAME,
                        multiline && TEXTAREA_CLASS_NAME,
                        withValidIcon &&
                            fieldTouched &&
                            !hasError &&
                            VALID_CLASS_NAME,
                        field.disabled && DISABLED_CLASS_NAME,
                        icon && !multiline && ICON_CLASS_NAME
                    )}
                    id={field.name}
                    {...field}
                    {...rest}
                />
            </div>
            {hasError && <InputNote>{errors[field.name]}</InputNote>}
        </div>
    )
}

Input.propTypes = {
    /**
     * Field Label
     */
    label: PropTypes.string,
    /**
     * The variant of the Input
     */
    variant: PropTypes.oneOf(['primary', 'secondary']),
    /**
     * The size of the Input
     */
    size: PropTypes.oneOf(['regular', 'small']),
    /**
     * Css class to pass to the wrapping div
     */
    className: PropTypes.string,
    /**
     * Css class to pass to the label
     */
    labelClassName: PropTypes.string,
    /**
     * Field object passed by the Formik Field component
     * contains { name, value, onChange, onBlur }
     */
    field: PropTypes.object.isRequired,
    /**
     * Form object passed by Formik
     * also containes values, setXXXX, handleXXXX, dirty, isValid, status, etc.
     */
    form: PropTypes.object.isRequired,
    /**
     * Set true to show the valid indicator icon
     */
    withValidIcon: PropTypes.bool,
    /**
     * Transforms the text field into a textarea
     */
    multiline: PropTypes.bool,
    /**
     * Imported svg to display as Input Icon
     */
    icon: PropTypes.elementType,
}

Input.defaultProps = {
    className: null,
    labelClassName: null,
    variant: 'primary',
    size: 'regular',
    withValidIcon: false,
    label: '',
    multiline: false,
    icon: null,
}

export default Input
