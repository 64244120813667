import _ from 'lodash'

/**
 * This is a Videojs implementation for a Button.
 *
 * @param {*} player 
 * @param {*} methods 
 */
const VolumeBarFactory = (player, methods = {}) => {
    const VolumeBarComponent = player.getFrameworkComponent('VolumeBar')

    class PlayerVolumeBar extends VolumeBarComponent {
        constructor(...params) {
            super(...params)
            if (_.isFunction(methods.constructor)) {
                methods.constructor.call(this, ...params)
            }
        }

        handleMouseUp(...params) {
            if (_.isFunction(methods.handleMouseUp)) {
                methods.handleMouseUp.call(this, ...params)
            }

            super.handleMouseUp(...params)
        }

        handleMouseMove(...params) {
            if (_.isFunction(methods.handleMouseMove)) {
                methods.handleMouseMove.call(this, ...params)
            }

            super.handleMouseMove(...params)
        }

        getHtmlEl() {
            return this.el()
        }

        addClass(classToAdd) {
            super.addClass(classToAdd)
        }
    }

    return PlayerVolumeBar
}

export default VolumeBarFactory
