import React from 'react'
/**
 * Context of the header
 */
const HeaderContext = React.createContext<{
    fixed: boolean
    menuOpen: boolean
    setMenuOpen: Function
}>({
    /**
     * Initial state of the Header
     */
    fixed: false,
    /**
     * Mobile menu is open
     */
    menuOpen: false,
    /**
     * Set state of the mobile menu
     * This will be initialized with a setState hook method
     */
    setMenuOpen: () => {},
})

export default HeaderContext
