import _ from 'lodash'
import VolumeBarFactory from '../../../../factory/volume-bar'
import MenuItemFactory from '../../../../factory/menu-item'
import { LocalStorage, LOCAL_STORAGE_KEYS } from '../../../../../utils/utils'

const createMusicVolume = player => {
    /**
     * Create label near the volume component
     *
     * @param {String} label
     */
    const getLabel = label => {
        const contentEl = document.createElement('div')
        contentEl.classList.add('gym-player__volume-label')
        contentEl.innerHTML = label

        return contentEl
    }

    const handleClick = event => {
        event.stopImmediatePropagation()
        event.stopPropagation()
    }

    const getVolumeButtonIcon = volume => {
        let level = 3
        if (!volume) {
            level = 0
        } else if (volume < 0.35) {
            level = 1
        } else if (volume < 0.7) {
            level = 2
        } else {
            level = 3
        }

        return level
    }

    const methods = {
        handleClick,
        handleMouseUp() {
            const callback = player.getOptions(
                'callback.audioSelector.musicVolume'
            )
            const percentage = this.getPercent()
            if (_.isFunction(callback)) {
                callback(percentage)
            }

            LocalStorage.set(LOCAL_STORAGE_KEYS.MUSIC_VOLUME, percentage)

            // force on click the icon
            menuItem.setAttribute(
                'aria-level',
                getVolumeButtonIcon(this.getPercent())
            )
        },
        handleMouseMove() {
            menuItem.setAttribute(
                'aria-level',
                getVolumeButtonIcon(this.getPercent())
            )
        },
    }

    const MenuItem = MenuItemFactory(player, {
        handleClick,
    })

    const Component = VolumeBarFactory(player, methods)

    const menuItem = new MenuItem(player, {
        label: player.i18n('music-options.volume'),
        selectable: false,
        handleClick,
    })

    /**
     * Check if video player supports volume, if not, hide button
     */
    if (player.tech_ && !player.tech_.featuresVolumeControl) {
        menuItem.addClass('gym-player__item--hidden')
    } else {
        menuItem.addClass('gym-player__volume-bar')
        menuItem.addClass('gym-player__menu-item')
        menuItem.addChild(getLabel(''))
    }

    const volumeBar = new Component(player)
    menuItem.addChild(volumeBar)

    /**
     * Set default value
     */
    const vol = Number.parseFloat(
        LocalStorage.get(LOCAL_STORAGE_KEYS.MUSIC_VOLUME)
    )
    const defaultVolume = !isNaN(vol)
        ? vol
        : player.getOptions('initial.musicVolume')

    if (!_.isNaN(defaultVolume)) {
        player.volume(defaultVolume)
    }
    menuItem.setAttribute('aria-level', getVolumeButtonIcon(defaultVolume))

    return menuItem
}

export { createMusicVolume }
