import _ from 'lodash'
import MenuItemFactory from '../../../../factory/menu-item'
import { LocalStorage, LOCAL_STORAGE_KEYS } from '../../../../../utils/utils'

const createMusicEmbed = player => {
    const TYPES = {
        nomusic: 'nomusic',
        music: 'music',
    }

    /**
     * Create a div with a checkbox and a label (ON or OFF) inside
     *
     * @param {*} player
     */
    const getCheckbox = player => {
        /**
         * Encapsulate everything inside a div
         */
        const option = document.createElement('div')
        const label = document.createElement('label')
        label.className = 'gym-player__embed-music--switch-background'

        /**
         * Label of the checkbox
         */
        const initialValue = TYPES[player.getAudioCategory()]
            ? player.getAudioCategory()
            : LocalStorage.get(LOCAL_STORAGE_KEYS.VIDEO_MUSIC) ||
              player.getOptions('initial.audioCategory')

        const setSwitch = isChecked => {
            const defaultClass = 'gym-player__embed-music--switch-wrapper'
            if (!isChecked) {
                return defaultClass
            }

            return `${defaultClass} checked`
        }

        option.className = setSwitch(initialValue !== TYPES.nomusic)

        /**
         * Create a checkbox
         */
        const checkbox = document.createElement('div')
        checkbox.className = 'gym-player__embed-music--ball'
        checkbox.onclick = e => {
            e.stopImmediatePropagation()
            e.stopPropagation()

            const isChecked = !_.includes(option.className, 'checked')
            const audioCategory = isChecked ? TYPES.music : TYPES.nomusic

            option.className = setSwitch(isChecked)

            player.setPlayerState({
                audioCategory,
            })

            /**
             * Trigger outside callback
             */
            const callback = player.getOptions(
                'callback.audioSelector.musicEmbed'
            )
            if (_.isFunction(callback)) {
                callback(audioCategory)
            }

            LocalStorage.set(LOCAL_STORAGE_KEYS.VIDEO_MUSIC, audioCategory)
        }

        option.appendChild(checkbox)
        option.appendChild(label)

        return {
            el: option,
            checkbox,
        }
    }

    const { el, checkbox } = getCheckbox(player)

    const methods = {
        handleClick: event => {
            event.stopImmediatePropagation()
            event.stopPropagation()
            checkbox.click()
        },
    }

    const Component = MenuItemFactory(player, methods)
    const item = new Component(player, {
        label: player.i18n('music-options.music'),
        selectable: false,
    })

    item.addClass('gym-player__embed')
    item.addClass('gym-player__menu-item')
    item.getHtmlEl().appendChild(el)

    return item
}

export { createMusicEmbed }
