import * as React from 'react'
import InputAdornment from '@mui/material/InputAdornment';
import Icon from 'components/icon'
import { ReactComponent as errorIcon } from 'svg/al-warning.svg'

import style from './text-field.module.scss'

export const getInputSlotsProps = (StartSlot?: string | React.FC, EndSlot?: string | React.FC, error?: boolean) => {
    return {
        ...(StartSlot && {
            startAdornment: (
                <InputAdornment className={style.adornment} position="start">
                    {StartSlot}
                </InputAdornment>
            )
        }),
        ...(EndSlot && {
            endAdornment: (
                <InputAdornment className={style.adornment} position="end">
                    {EndSlot}
                </InputAdornment>
            )
        }),
        ...(error && {
            endAdornment: (
                <InputAdornment className={style.adornment} position="end">
                    <Icon data={errorIcon} className={style.errorIcon} />
                </InputAdornment>
            )
        }),
    }
}
