import React from 'react'
import { injectIntl } from 'react-intl'

/**
 * localizeField HOC
 * translates label, placeholder and error message of Formik fields
 * @param {*} props
 */
const localizeField = (WrappedComponent) => {
    // eslint-disable-next-line react/prefer-stateless-function
    class HOC extends React.Component {
        render() {
            // eslint-disable-next-line
            const { label, placeholder, i18nValues, intl, form, options, translateOptions = false, ...rest } = this.props
            const translatedLabel = label && intl.formatMessage({ id: label })
            const translatedPlaceholder = placeholder && intl.formatMessage({ id: placeholder })
            const translatedi18nValues = i18nValues
                && Object.keys(i18nValues)
                    .reduce(
                        (acc, item) => ({
                            ...acc,
                            [item]: intl.formatMessage({ id: i18nValues[item] })
                        }),
                        {}
                    )
            const translatedError = form.touched[rest.field.name]
                && form.errors[rest.field.name]
                && typeof form.errors[rest.field.name] === 'string'
                && intl.formatMessage({ id: form.errors[rest.field.name] })
            const translatedOptions = translateOptions && options
                && options
                    .map(option => ({ ...option, label: intl.formatMessage({ id: option.label }) }))

            return (
                <WrappedComponent {...{
                    ...rest,
                    label: translatedLabel,
                    options: translatedOptions || options,
                    placeholder: translatedPlaceholder,
                    ...(translatedi18nValues && { i18nValues: translatedi18nValues }),
                    form: {
                        ...form,
                        errors: {
                            ...form.errors,
                            [rest.field.name]: translatedError || form.errors[rest.field.name]
                        }
                    }
                }}
                />
            )
        }
    }

    return injectIntl(HOC)
}

export default localizeField
