import moment from 'moment'
import _ from 'lodash'

/**
 * Generate Select values containing years between now minus age gap (16) and 100 years
 * @returns {*}
 */
const generateYears = () => {
    const MAX_AGE = 16
    const upperLimit = parseInt(moment().get('year'), 10) - (MAX_AGE - 1)
    const lowerLimit = parseInt(moment().get('year'), 10) - 100

    return _.rangeRight(lowerLimit, upperLimit).map(year => ({
        label: year,
        value: year,
    }))
}

/**
 * Generate Select values from an array when passed to a map function
 * @param {*} val value
 * @param {Number} index index of value
 * @returns {*}
 */
export const mapToFieldOptions = (val, index) => ({
    label: val || index + 1,
    value: index + 1,
})

/**
 * Generate Select options containing short names of months
 * The value of select option is month's number starting with 1
 * @returns {Array} An array with select options
 */
const generateMonths = () => moment.monthsShort().map(mapToFieldOptions)

/**
 * Generate Select options containing days of month and year
 * @param {Number} year year of month
 * @param {Number} month month to generate days for
 * @returns {Array} An array with select options
 */
const generateDays = (year, month) => {
    const number =
        (year && month && moment([year, month - 1, 1]).daysInMonth()) || 31

    return new Array(number).fill().map(mapToFieldOptions)
}

/**
 * Gets the current date format separated by "-"
 *
 * @param {*} intl React intl object
 * @returns {String} Current date Format. example: MM-DD-YYYY
 */
const getCurrentDateFormat = intl => {
    const ISO_DATE = '2019-07-08'
    const dateParts = ISO_DATE.split('-')

    const formattedDate = intl.formatDate(ISO_DATE, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    })

    const dateFormat = formattedDate
        .replace(/\//g, '-')
        .replace(/\./g, '-')
        .replace(/[^0-9-]/g, '') // force to remove unexpected chars
        .replace(dateParts[0], 'YYYY')
        .replace(dateParts[1], 'MM')
        .replace(dateParts[2], 'DD')

    const possibleDates = [
        'DD-MM-YYYY',
        'MM-DD-YYYY',
        'YYYY-MM-DD',
        'YYYY-DD-MM',
    ]

    if (possibleDates.indexOf(dateFormat) >= 0) {
        return dateFormat
    }

    return possibleDates[0]
}

export { generateYears, generateMonths, generateDays, getCurrentDateFormat }
