import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

/**
 * Page Footer
 */
const Footer = ({ className= '', children }) => (
    <footer className={cn('footer', className)}>{children}</footer>
)

Footer.propTypes = {
    /**
     * Use <FooterLogo/> and <FooterSection/>
     * to structure elements inside Footer.
     * See example in Storybook
     */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,

    /**
     * CSS className applied to wrapping element
     */
    className: PropTypes.string,
}

export default Footer
