import React, { useState } from 'react'
import cn from 'classnames'

import CookieBanner from './component/cookie-banner'
import { useScroll, usePrevious } from '../../hooks'
import HeaderContext from './header-context'

const BASE_CLASS_NAME = 'top-header'
const SCROLLY_LIMIT = 250

/**
 * Calculates if the header is in fixed state or not based on current scrollY and previous scrollY
 * @param {Number} currentScroll - Current scrollY position of the page
 * @param {Number} previousScroll - Previous scrollY position of the page
 */
const isFixed = (currentScroll: number, previousScroll: number) =>
    previousScroll >= currentScroll && currentScroll > SCROLLY_LIMIT

/**
 * Returns a className on current page scroll and previous page scroll
 * @param {Number} currentScroll - Current scrollY position of the page
 * @param {Number} previousScroll - Previous scrollY position of the page
 */
const getClassName = (currentScroll: number, previousScroll: number) => {
    let topBarClass = ''
    if (currentScroll <= SCROLLY_LIMIT) {
        topBarClass = `${BASE_CLASS_NAME}--initial`
    } else if (isFixed(currentScroll, previousScroll)) {
        topBarClass = cn(
            `${BASE_CLASS_NAME}--white`,
            `${BASE_CLASS_NAME}--fixed`
        )
    }

    return topBarClass
}

export interface HeaderProps {
    alwaysTop?: boolean
    isAbsolute?: boolean
    handleCookieAccept?: () => void
    cookieText?: React.FC
    cookieMobileText?: React.FC
    hideCookieBanner?: boolean
    cookieBannerLocalStorageKey?: string
    Banner?: React.FC<{}> | string
    className?: string
    children: React.ReactNode
}

/**
 * Page Header
 */
const Header: React.FC<HeaderProps> = ({
    className,
    children,
    alwaysTop = false,
    isAbsolute = true,
    handleCookieAccept = () => {},
    cookieText = '',
    cookieMobileText = '',
    cookieBannerLocalStorageKey = 'cookieBannerConsent',
    hideCookieBanner = false,
    Banner,
}) => {
    const scrollY = useScroll()
    const previous = usePrevious(scrollY)
    const [menuOpen, setMenuOpen] = useState(false)
    const topBarClassName = alwaysTop
        ? cn(`${BASE_CLASS_NAME}--white`, `${BASE_CLASS_NAME}--relative`)
        : getClassName(scrollY, previous)
    const fixed = isFixed(scrollY, previous)
    const contextFixedValue = alwaysTop ? false : fixed

    return (
        <header
            className={cn(
                'header',
                BASE_CLASS_NAME,
                topBarClassName,
                className
            )}
        >
            <HeaderContext.Provider
                value={{
                    fixed: contextFixedValue,
                    menuOpen,
                    setMenuOpen,
                }}
            >
                {!hideCookieBanner && (
                    <CookieBanner
                        onAccept={handleCookieAccept}
                        content={cookieText}
                        mobileContent={cookieMobileText}
                        localStorageKey={cookieBannerLocalStorageKey}
                    />
                )}
                {Banner && <Banner />}
                <div
                    className={cn('top-header__wrapper', {
                        'top-header__absolute': isAbsolute,
                    })}
                >
                    <section className={`${BASE_CLASS_NAME}__content`}>
                        {children}
                    </section>
                </div>
            </HeaderContext.Provider>
        </header>
    )
}

export default Header
