import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { FormattedMessage } from 'react-intl'
import { FluidWrapper } from '../'

import style from './subhero-image.module.scss'

const SubheroImage = ({ BackgroundImage, messages, wrapperClass, children }) => (
    <FluidWrapper className={cn(style.subheroContent, wrapperClass)}>
        <BackgroundImage />
        <div className={style.footerTextWrapper}>
            {
                messages.title && (
                    <h2 className={style.footerTitle}>
                        <FormattedMessage id={messages.title} />
                    </h2>
                )
            }
            {
                messages.subtitle && (
                    <div className={style.footerSubtitle}>
                        <FormattedMessage id={messages.subtitle} />
                    </div>
                )
            }
            {children}
        </div>
    </FluidWrapper>
)

SubheroImage.defaultProps = {
    wrapperClass: '',
    messages: {}
}

SubheroImage.propTypes = {
    BackgroundImage: PropTypes.func.isRequired,
    messages: PropTypes.shape({
        title: PropTypes.string,
        subtitle: PropTypes.string,
    }),
    wrapperClass: PropTypes.string,
    children: PropTypes.node.isRequired,
}

export default SubheroImage
