import _ from 'lodash'

/**
 * Show the annotations on the video (Name of the exercise, 
 * Time remaining to finish the workout, ...)
 * 
 * This component depends on:
 * - https://github.com/brightcove/videojs-overlay 
 */

const setAnnotations = ({ player }) => {

    const annotations = player.getOptions('annotations')
    if (_.isEmpty(annotations)) {
        return {}
    }

    const overlays = []
    const createMarker = (classNames, align) => {
        const timerExerciseEl = document.createElement('span')
        _.forEach(classNames, className => timerExerciseEl.classList.add(className))
        overlays.push({
            content: timerExerciseEl,
            align,
            start: 0
        })

        return timerExerciseEl
    }

    /**
     * Bottom left marker
     */
    const topLeft = createMarker(
        ['gym-player__overlay--heading', 'gym-player__modal-sensitive'], 'top-left')
    const bottomRight = createMarker(
        ['gym-player__overlay--timer-video', 'gym-player__modal-sensitive'], 'bottom-right')
    const bottomLeft = createMarker(
        ['gym-player__overlay--subheading', 'gym-player__modal-sensitive'], 'bottom-left')

    const toMMSS = mili => {
        const minutes = `${parseInt(mili / 60 / 1000)}`.padStart(2, 0)
        const seconds = `${parseInt((mili - minutes * 60 * 1000)/1000)}`.padStart(2, 0)

        return `${minutes}:${seconds}`
    }

    const updateMarkerHeading = (el, type) => {
        // avoid exception when video is playing and is disposed
        if (!(player && player.el_)) {
            return;
        }

        const time = player.player_ && _.isFunction(player.currentTime) && player.currentTime() * 1000
        const annotation = annotations
            .find(annotation => annotation.start <= time && annotation.end >= time)

        if (annotation) {
            const showTimer = annotation.start + annotation.startProgress < time
            
            const remainingExercTime = annotation.end - (player.currentTime() * 1000)
            const formatedRemaining = showTimer && !_.isNaN(remainingExercTime) 
                ? toMMSS(remainingExercTime)
                : ''

            el.innerHTML = type === 'subHeading'
                ? `${annotation[type]} ${formatedRemaining}`
                : `${annotation[type]}`
            el.classList.remove('vjs-hidden')
        } else {
            el.classList.add('vjs-hidden')
        }
    }

    const updateMarkerTime = () => {

        // avoid exception when video is playing and is disposed
        if (!(player && player.el_)) {
            return;
        }

        const time = (player.duration() - player.currentTime()) * 1000
        bottomRight.innerHTML = !_.isNaN(time) ? toMMSS(time) : ''
    }

    const updateTime = _.throttle(() => {
        updateMarkerHeading(topLeft, 'subHeading')
        updateMarkerHeading(bottomLeft, 'heading')
        updateMarkerTime()
    }, 1000)

    player.onTimeUpdate(updateTime)

    player.setOverlay({overlays})
}

export { setAnnotations }
