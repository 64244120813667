import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import InputNote from '../common/InputNote'
import InputLabel from '../common/InputLabel'

import style from './dropdown.module.scss'

const BASE_CLASS_NAME = 'select'
const DISABLED_CLASS_NAME = `${BASE_CLASS_NAME}--disabled`
const ERROR_CLASS_NAME = `${BASE_CLASS_NAME}--invalid`
const VARIANT_CLASSNAME_MAP = {
    primary: `${BASE_CLASS_NAME}--primary`,
    secondary: `${BASE_CLASS_NAME}--secondary`,
}

/**
 * Render an option for the Select Dropdown
 * @param {*} option
 */
const renderOptionItem = option => (
    <option key={option.label} value={option.value}>
        {option.label}
    </option>
)

/**
 * Dropdown Input
 */
const Dropdown = ({
    className,
    variant,
    field,
    form: { touched, errors, initialValues, setFieldValue },
    form,
    label,
    placeholder,
    options,
    ...rest
}) => {
    // Fallback to initial value if the selected one is not in the options
    const isExistingValue =
        field.value === initialValues[field.name] ||
        options.find(option => `${option.value}` === field.value)

    if (!isExistingValue) {
        setFieldValue(field.name, initialValues[field.name])
    }
    const placeholderActive = placeholder && field.value === ''
    const hasError = touched[field.name] && errors[field.name]

    return (
        <div className={cn('input-container', className)}>
            {label && <InputLabel htmlFor={field.name}>{label}</InputLabel>}
            <select
                className={cn(
                    BASE_CLASS_NAME,
                    VARIANT_CLASSNAME_MAP[variant],
                    field.disabled && DISABLED_CLASS_NAME,
                    placeholderActive && style.placeholder,
                    hasError && ERROR_CLASS_NAME
                )}
                id={field.name}
                {...field}
                {...rest}
            >
                {placeholder && (
                    <option value="" disabled style={{ display: 'none' }}>
                        {placeholder}
                    </option>
                )}
                {options.map(option => renderOptionItem(option))}
            </select>
            {hasError && <InputNote>{errors[field.name]}</InputNote>}
        </div>
    )
}

Dropdown.propTypes = {
    /**
     * Field Label
     */
    label: PropTypes.string,
    /**
     * The variant of the Dropdown
     */
    variant: PropTypes.oneOf(['primary', 'secondary']),
    /**
     * Css class to pass to the wrapping div
     */
    className: PropTypes.string,
    /**
     * Field object passed by the Formik Field component
     * contains { name, value, onChange, onBlur }
     */
    field: PropTypes.object.isRequired,
    /**
     * Form object passed by Formik
     * also contains values, setXXXX, handleXXXX, dirty, isValid, status, etc.
     */
    form: PropTypes.object.isRequired,
    /**
     * Placeholder
     */
    placeholder: PropTypes.node,
    /**
     * Options for the Dropdown select
     */
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.node,
            value: PropTypes.node,
        })
    ).isRequired,
}

Dropdown.defaultProps = {
    label: null,
    className: null,
    variant: null,
    placeholder: null,
}

export default Dropdown
