import _ from 'lodash'
import { getCalculator } from './component/calculator/calculator'

/**
 * Sets when the Cue Points should be shown. At the moment, 
 * we just have the Calculator as a Cue Point
 *
 * @param {*} player 
 */
const setCuePoints = ({ player }) => {

    const challenges = player.getOptions('challenges')
    if (_.isEmpty(challenges)) {
        return {}
    }

    const modal = player.createModal('', {
        temporary: false,
    })
    modal.close() // TODO: uncomment this line

    /**
     * For each challenge, wrap it and provide functionality
     * to avoid being showed when closed
     */
    const challengesInfo = challenges.map(challenge => ({
        closed: false,
        setClosed(closed) {
            this.closed = closed
        },
        info: challenge,
        content: getCalculator(player, modal, challenge)
    }))

    // modal.fillWith(challengesInfo[0].content) // TODO: remove this line

    const getCurrentChallenge = () => {
        const time = player && player.player_
            && _.isFunction(player.currentTime) && parseInt(player.currentTime() * 1000)

        return time && challengesInfo
            .find(challenge => challenge.info.feedbackStart <= time
                && challenge.info.feedbackEnd >= time && !challenge.closed)
    }

    const triggerCallback = (challenge, event) => {
        const callback = player.getOptions(`callback.challenge.${event}`)
        if (_.isFunction(callback)) {
            callback(_.get(challenge, 'info.id'))
        }
    }

    modal.on('modalopen', () => {
        /**
         * hide elements
         */
        let els = document.getElementsByClassName('gym-player__modal-sensitive')
        els = _.union(document.getElementsByClassName('vjs-play-control'), els)
        _.forEach(els, el => el.classList.add('gym-player__item--hidden'))

        const challenge = getCurrentChallenge()
        triggerCallback(challenge, 'show')
    })

    modal.on('modalclose', () => {
        const challenge = getCurrentChallenge()
        if (challenge) {
            challenge.setClosed(true)
        }

        /**
         * show hidden elements
         */
        let els = document.getElementsByClassName('gym-player__modal-sensitive')
        els = _.union(document.getElementsByClassName('vjs-play-control'), els)
        _.forEach(els, el => el.classList.remove('gym-player__item--hidden'))

        triggerCallback(challenge, 'close')
    })

    const showCuePoint = () => {
        /**
         * Get challenge
         */
        const challenge = getCurrentChallenge()

        /**
         * No challenge and the modal is open, close it
         */
        if (!challenge && modal.opened()) {
            modal.close()
            player.play()
        } else if (challenge && !challenge.closed) {
            modal.fillWith(challenge.content)
            modal.open()
            player.pause()
            // track
        }
    }

    const updateTime = _.throttle(showCuePoint, 1000)
    player.onTimeUpdate(updateTime)
}

export {
    setCuePoints
}