import _ from 'lodash'
import { getSource } from './adaptive-streaming/adaptive-streaming'
import PLAYER_DEFAULT_CONFIG from '../config/default'
import Utils from '../utils/utils'
import { setHotKeys } from './component/hotkey/hotkey'
import packageJson from '../../package.json'

const PlayerFactory = (
    videoFramework,
    videoTag,
    options = {},
    videoFrameworkOptions = {}
) => {
    let tag = videoTag
    if (typeof videoTag === 'string') {
        tag = window.document.getElementById(videoTag)
    }

    if (!tag) {
        throw new Error(`Couldn't find any HTML video element`)
    }

    /**
     * Set log level to error only
     */
    if (videoFramework && videoFramework.log && videoFramework.log.level) {
        videoFramework.log.level(options.logLevel || 'error')
    }

    /**
     * Avoid error: "Failed to execute 'appendBuffer' on 'SourceBuffer': The SourceBuffer
     * is full, and cannot free space to append additional buffers."
     * and
     * "QuotaExceededError"
     */
    if (videoFramework && videoFramework.Hls) {
        videoFramework.Hls.MAX_GOAL_BUFFER_LENGTH = 30
    }

    /**
     * Instantiate new player (it may vary to other technologies)
     */
    const player = videoFramework(tag, videoFrameworkOptions)

    /**
     * Private Variables
     */
    let _audioLanguage
    let _audioCategory
    let _quality
    let _canPlayThrough = false

    /**
     * These are mandatory functions that should be implemented
     * on the player
     */
    const mandatory = {
        createModal: player.createModal,
        play: player.play,
        currentTime: player.currentTime,
        duration: player.duration,
        qualityLevels: player.qualityLevels,
        markers: player.markers,
        volume: player.volume,
    }

    player.on('canplaythrough', () => {
        _canPlayThrough = true
    })

    return _.extend(player, mandatory, {
        /**
         * Get child basic component to extend
         */
        getFrameworkComponent(key) {
            return videoFramework.getComponent(key)
        },
        getVideoFramework() {
            return videoFramework
        },
        /**
         * Register a new component
         */
        registerFrameworkComponent(componentName, Component) {
            videoFramework.registerComponent(componentName, Component)
        },
        getOptions(key) {
            if (!key) {
                throw new Error('Key of the options must be explicit')
            }
            const value = _.get(options, key)
            if (value !== undefined) {
                return value
            }
            return value || _.get(PLAYER_DEFAULT_CONFIG.options, key)
        },
        i18n(key) {
            return Utils.i18n(player, key)
        },
        registerComponents(components) {
            /**
             * register custom components
             */
            _.forEach(components, component => {
                if (component.instance) {
                    videoFramework.registerComponent(
                        component.name,
                        component.instance
                    )
                }
            })
        },
        setSource(audioLanguage, audioCategory) {
            const sources = this.getOptions('sources')
            _audioLanguage = audioLanguage
            _audioCategory = audioCategory
            const src = getSource(sources, audioLanguage, audioCategory)
            this.src(src)
        },
        addPlayerChild(isControlBar, ...params) {
            if (isControlBar) {
                this.controlBar.addChild(...params)
            } else {
                this.addChild(...params)
            }
        },
        setOverlay(overlay) {
            if (_.isFunction(player.overlay)) {
                this.overlay(overlay)
            }
        },
        onTimeUpdate(func) {
            this.on('timeupdate', func)
        },
        onReady(func) {
            return this.ready(func)
        },
        setQuality(quality) {
            _quality = quality
        },
        getQuality() {
            return _quality
        },
        getAudioCategory() {
            return _audioCategory
        },
        /**
         * This are the properties that will be kept between the load of different
         * video formats (with/without audio, ...)
         */
        getPlayerState() {
            return {
                currentTime: this.currentTime(),
                isPaused: this.paused(),
                volume: this.volume(),
                quality: _quality,
                audioCategory: _audioCategory,
                audioLanguage: _audioLanguage,
            }
        },
        /**
         * Get the current state and apply changes. Normally should be
         * used to change audio language, audio category and audio on/off.
         * This is used, because the manifest files are different for each
         * language, category and on/off gymondo sound
         *
         * @param {*} properties
         */
        setPlayerState(properties) {
            let state = this.getPlayerState()
            state = _.extend(state, properties)
            _canPlayThrough = false
            /**
             * Resets the player to the previous state
             *
             * @param {Number} attemptNr: Number of attemps to reset
             *  the player to the previous state
             */
            const _toSavedState = attemptNr => {
                if (attemptNr <= 100 && !_canPlayThrough) {
                    _toSavedState(attemptNr + 1)
                }
                this.currentTime(state.currentTime)
                this.volume(state.volume)
                if (state.isPaused) {
                    /**
                     * Avoid showing default button when changing configuration
                     */
                    this.play()
                    this.pause()
                } else {
                    this.play()
                }
                if (this.qualityLevels) {
                    this.qualityLevels().trigger({
                        type: 'sourcechanged',
                    })
                }
            }
            // set new state to the player
            this.setSource(state.audioLanguage, state.audioCategory)
            _toSavedState(0)
        },
        toggle() {
            if (this.paused()) {
                this.play()
            } else {
                this.pause()
            }
        },
        setHotKeys() {
            setHotKeys({ player })
        },
        getGymPlayerVersion() {
            return packageJson.version
        },
    })
}

export default PlayerFactory
