import _ from 'lodash'
import { createMenuItem } from './component/language-item/language-item'
import MenuButtonFactory from '../../factory/menu-button'

const createItems = player => {
    const sources = player.getOptions('sources')
    const languages = _.uniq(sources.map(it => it.language))
    if (languages.length <= 1) {
        return []
    }

    const selLang = player.getOptions('audioLanguage')

    let menuItems = []

    const items = languages.map(lang =>
        createMenuItem(player, lang, selLang === lang, menuItems)
    )
    menuItems = [...items]

    return menuItems
}

/**
 * Create a MenuButton with all the items as child. If items is empty, return just the icon.
 * It is populated when the qualityLevels is available
 *
 * @param {*} videoFramework
 * @param {*} componentName
 * @param {*} items: List of MenuItems
 */
const createLanguageSelector = ({ player, componentName, isControlBar }) => {
    if (_.isEmpty(player.getOptions('sources'))) {
        return {}
    }

    const items = createItems(player)
    const methods = {
        title: player.i18n('language.header'),
        createItems() {
            return items
        },
        buildCSSClass() {
            const cln = _.isEmpty(items)
                ? 'gym-player__menu-button--hidden'
                : ''

            return `gym-player__language-icon gym-player__menu-button vjs-icon-audio vjs-icon-placeholder ${cln}`
        },
    }

    const item = MenuButtonFactory(player, methods)

    /**
     * Register and add child to player
     */
    player.registerFrameworkComponent(componentName, item)
    player.addPlayerChild(isControlBar, componentName)
}

export { createLanguageSelector }
