import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import HeaderContext from '../header-context'

import style from './navigation-item.module.scss'

/**
 * Navigation Item
 */
const NavigationItem = ({
    children,
    initial = false,
    fixed = false,
    className = null,
    mobile = false,
}) => {
    const { fixed: isFixed } = useContext(HeaderContext)
    const shouldAlwaysShow = (initial && fixed) || (!initial && !fixed)

    if (
        (!shouldAlwaysShow && !isFixed && fixed) ||
        (!shouldAlwaysShow && isFixed && initial)
    ) {
        return null
    }

    return (
        <li
            className={cn(
                mobile ? 'menu-drawer-actions__item' : 'top-nav__item',
                isFixed && fixed && style.fixed,
                !isFixed && initial && style.initial,
                className
            )}
        >
            {children}
        </li>
    )
}

NavigationItem.propTypes = {
    /**
     * Content of the NavigationItem
     */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    /**
     * Set if item is visible in intial state of Header
     */
    initial: PropTypes.bool,
    /**
     * Set if item is visible in fixed(scroll) state of Header
     */
    fixed: PropTypes.bool,
    /**
     * CSS className added to wrapping element
     */
    className: PropTypes.string,
    /**
     * Set if item is mobile (apply specific mobile css class)
     */
    mobile: PropTypes.bool,
}

export default NavigationItem
