import _ from 'lodash'

/**
 * This functions receives the urls parameter (normally from the backend) and extract
 * the parameters used on the player
 *
 * @param {*} urls: Values with id and url. Example: { id: 'en_music.mpd', url: 'http://....mpd' }
 * @return
 */
const convertToSource = urls => {
    /**
     * This Regex identifies the files that contain the proper manifests.
     * The proper manifests are similar to: de_nomusic.mpd
     */
    const REGEX = /^(?<language>\w{2})_(?<category>(no)?music)\.(?<type>\w{3,4})$/

    const TYPE = {
        mpd: 'application/dash+xml',
        m3u8: 'application/x-mpegURL',
    }

    return _.orderBy(
        _.map(urls, url => {
            if (!REGEX.test(url.id)) {
                return null
            }
            const parameters = REGEX.exec(url.id)

            return {
                ...(parameters && parameters.groups),
                url: url.url,
                type:
                    TYPE[
                        parameters &&
                            parameters.groups &&
                            parameters.groups.type
                    ],
            }
        }),
        'type'
    ).filter(it => it)
}

const i18n = (player, key) => {
    return player.localize(key)
}

export const LocalStorage = {
    get: key =>
        window.localStorage.getItem(key)
            ? JSON.parse(localStorage.getItem(key))
            : null,
    set: (key, value) =>
        window.localStorage.setItem(key, JSON.stringify(value)),
}

export const LOCAL_STORAGE_KEYS = {
    MUSIC_VOLUME: 'musicVolume',
    VIDEO_QUALITY: 'videoQualityNew',
    VIDEO_MUSIC: 'videoMusic',
}

export default {
    convertToSource,
    i18n,
}
