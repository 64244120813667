import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { Checkbox } from '../'

/**
 * Localized Input component
 * translates label, placeholder and error message
 * @param {*} props
 */
const CheckboxLocalized = (props) => {
    const { label, form, ...rest } = props
    const intl = useIntl()
    const translatedLabel = label && intl.formatMessage({ id: label })
    const translatedError = form.touched[rest.field.name] && form.errors[rest.field.name] && intl
        .formatMessage({ id: form.errors[rest.field.name] })

    return (
        <Checkbox {...{
            ...rest,
            label: translatedLabel,
            form: {
                ...form,
                errors: {
                    ...form.errors,
                    [rest.field.name]: translatedError
                }
            }
        }}
        />
    )
}

CheckboxLocalized.propTypes = {
    /**
    * Field Label
    * pass a FromattedMessageId here
    */
    label: PropTypes.string,
    /**
    * The variant of the Input
    */
    variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
    /**
    * Css class to pass to the wrapping div
    */
    className: PropTypes.string,
    /**
    * Field object passed by the Formik Field component
    * contains { name, value, onChange, onBlur }
    */
    field: PropTypes.object.isRequired,
    /**
     * Form object passed by Formik
     * also containes values, setXXXX, handleXXXX, dirty, isValid, status, etc.
     */
    form: PropTypes.object.isRequired
}

CheckboxLocalized.defaultProps = {
    className: null,
    variant: 'primary',
    label: ''
}

export default CheckboxLocalized
