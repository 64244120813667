import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '../'
import { useScroll } from '../../hooks'
import arrow from './back_to_top_arrow_negative.svg'

import style from './scroll-top-arrow.module.scss'

const ScrollTopArrow = ({ displayScrollLimit }) => {
    const scrollY = useScroll()

    if (scrollY < displayScrollLimit) {
        return null
    }

    return (
        <Icon
            className={style.icon}
            data={arrow}
            onClick={() => window.scroll(0, 0)}
        />
    )
}

ScrollTopArrow.defaultProps = {
    displayScrollLimit: 650
}

ScrollTopArrow.propTypes = {
    displayScrollLimit: PropTypes.number
}

export default ScrollTopArrow
