import cn from 'classnames'
import { useController } from "react-hook-form";
import React, { useCallback } from 'react'

import Item from './component/item'
import { ItemGroupProps, ItemGroupVariants } from './types'

import style from './item-group.module.scss'

const ItemGroup = ({
    className,
    itemClassName,
    name,
    items,
    multiple = false,
    disabled = false,
    variant = ItemGroupVariants.list,
    tileItemXsCol = 6,
    tileItemSmCol = 6,
    tileItemMdCol = 4,
    tileItemLgCol = 3,
}: ItemGroupProps) => {
    const {
        field: { onChange, onBlur, value: selected, name: controlName, ref },
    } = useController({ name })

    const selectItem = useCallback((value) => {
        if (multiple) {
            const selectedItemsArr = (Array.isArray(selected) && selected) || []

            onChange(
                selectedItemsArr?.includes(value) ?
                    selectedItemsArr.filter(((selectedValue: string) => selectedValue !== value)) :
                    [...selectedItemsArr, value]
            )
        } else {
            onChange(value)
        }
    }, [onChange, multiple, selected])

    return (
        <div
            className={cn('row', style.itemsGroupContainer, className)}
            ref={ref}
            onBlur={onBlur}
            role="listbox"
            aria-label={controlName}
            aria-multiselectable={multiple}
            aria-orientation={variant === ItemGroupVariants.list ? 'vertical' : 'horizontal'}
        >
            {items?.map(({ value, ...restProps }) => (
                <section
                    key={value}
                    className={cn({
                        [`col-xs-${tileItemXsCol} col-sm-${tileItemSmCol} col-md-${tileItemMdCol} col-lg-${tileItemLgCol}`]: variant === ItemGroupVariants.tile,
                        'col-xs-12': variant === ItemGroupVariants.list
                    })}
                >
                    <Item
                        className={itemClassName}
                        value={value}
                        variant={variant}
                        disabled={disabled}
                        isSelected={multiple ? Array.isArray(selected) && selected?.includes(value) : (selected === value)}
                        onClick={selectItem}
                        {...restProps}
                    />
                </section>
            ))}
        </div>
    )
}

export default ItemGroup
