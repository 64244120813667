import React, { useState, useEffect } from 'react'
import cn from 'classnames'

import Icon from 'components/icon'

import closeSvg from '../../../svg/u-close.svg'
import style from './cookie-banner.module.scss'

const localStorage = (typeof window !== 'undefined' && window.localStorage) || {
    _data: {},
    setItem(id, val) {
        (this as Storage)._data[id] = String(val)
    },
    getItem(id) {
        return Object.prototype.hasOwnProperty.call((this as Storage)._data, id)
            ? (this as Storage)._data[id]
            : undefined
    },
    removeItem(id) {
        delete (this as Storage)._data[id]
    },
    clear() {
        (this as Storage)._data = {}
    },
}

interface CookieBannerProps {
    localStorageKey: string
    onAccept?: () => void
    content: React.FC | string
    mobileContent: React.FC | string
}

const CookieBanner: React.FC<CookieBannerProps> = ({
    onAccept = () => {},
    localStorageKey = 'cookies.accepted',
    content,
    mobileContent,
}) => {
    const [accepted, setAccepted] = useState(true)
    const cookieExists = localStorage.getItem(localStorageKey)
    const Content = content
    const MobileContent = mobileContent

    useEffect(() => {
        if (!cookieExists) {
            setAccepted(false)
        }
    }, [cookieExists])

    const acceptCookie = () => {
        localStorage.setItem(localStorageKey, 'true')
        setAccepted(true)
        onAccept()
    }

    if (accepted) {
        return null
    }

    return (
        <section className={style.notice}>
            <div className={style.notice__content}>
                <div className={cn(style.notice__text, style.desktopContent)}>
                    <Content />
                </div>
                <div className={cn(style.notice__text, style.mobileContent)}>
                    <MobileContent />
                </div>

                <span className={style.notice__close} onClick={onAccept}>
                    <Icon
                        data={closeSvg}
                        onClick={acceptCookie}
                        className={style.notice__svg}
                    />
                </span>
            </div>
        </section>
    )
}

export default CookieBanner
