export default {
    'Play': 'Wiedergabe',
    'Pause': 'Pause',
    'Fullscreen': 'Vollbild',
    'Non-Fullscreen': 'Kein Vollbild',
    'previous-exercise.skip-none': 'Zum Anfang',
    'previous-exercise.skip-challenge': 'Vorherige Übung',
    'previous-exercise.skip-exercise': 'Vorherige Übung',
    'next-exercise.skip-none': ' ',
    'next-exercise.skip-challenge': 'Challenge überspringen',
    'next-exercise.skip-exercise': 'Übung überspringen',
    'quality.header': 'Qualität',
    'quality.auto': 'Auto',
    'quality.HD': 'HD',
    'quality.4k': '4K',
    'calculator.question': 'Wieviele Wiederholungen hast du geschafft?',
    'language.header': 'Sprachen',
    'language.de': 'Deutsch',
    'language.en': 'Englisch',
    'music-options.volume': 'Lautstärke Trainer',
    'music-options.spotify': 'Spotify',
    'music-options.spotify.title': 'Lautstärke in Spotify direkt ändern',
    'music-options.music': 'Gymondo Musik',
    'music-options.music.music': 'AN',
    'music-options.music.nomusic': 'AUS',
    'music-options.header': 'Ton',
}
