function requestInterceptor(axios, configureRequest) {
    axios.interceptors.request.use(
        config => configureRequest(config),
        err => Promise.reject(err)
    )

    return axios
}

export default requestInterceptor
