import _ from 'lodash'

/**
 * This is a Videojs implementation for a Button.
 *
 * @param {*} player 
 * @param {*} methods 
 */
const ButtonFactory = (player, methods = {}) => {
    const ButtonComponent = player.getFrameworkComponent('Button')

    class PlayerButton extends ButtonComponent {
        constructor(...params) {
            super(...params)
            if (_.isFunction(methods.constructor)) {
                methods.constructor.call(this, ...params)
            }
        }

        handleClick(...params) {
            if (_.isFunction(methods.handleClick)) {
                return methods.handleClick.call(this, ...params)
            }

            return super.handleClick(...params)
        }

        buildCSSClass() {
            if (_.isFunction(methods.buildCSSClass)) {
                return methods.buildCSSClass.call(this)
            }

            return super.buildCSSClass()
        }

        getControlText() {
            return this.el().querySelector(".vjs-control-text")
        }
    }

    return PlayerButton
}

export default ButtonFactory
