import { ProgramV1, Program } from 'types'

export const mapToV2Program = (program: ProgramV1): Program => ({
    slug: program.slug,
    id: program.id,
    recommendationId: program.recommendationId,
    title: program.title,
    level: program.level,
    continuous: program.continuous,
    followupProgramId: program.followupProgramId,
    goal: program.goal,
    configuration: {
        durationWeeks: program.durationWeeks,
        minTrainingDays: program.mobileConfig.minTrainingDays,
        maxTrainingDays: program.mobileConfig.maxTrainingDays,
        optimalTrainingDays: [1, 2, 3, 4, 5, 6, 7].filter(
            day => !program.optimalRestDays.includes(day)
        ),
        replaceWorkoutAllowed: program.mobileConfig.replaceAllowed,
    },
    images: {
        previews: [],
        usps: [],
        testimonials: [],
        web: {
            logo: `${program.imageUrl}webapp-titletext.svg`,
            background: {
                small: `${program.imageUrl}mobile-titleimage-2x.jpg`,
                medium: `${program.imageUrl}mobile-titleimage-2x.jpg`,
                large: `${program.imageUrl}webapp-detail-1080.jpg`,
            },
            promo: { large: `${program.imageUrl}promopic.jpg` },
        },
    },
    videos: {
        preview: program.videoUrl ? `${program.videoUrl}_360.mp4` : undefined,
    },
})
