import React from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import spinner from './spinner.gif'

import style from './loading-spinner.module.scss'

const TYPE_TO_CLASS = {
    page: style.pageSpinnerWrapper,
    component: style.componentSpinnerWrapper,
}

const TYPE_TO_IMG_CLASS = {
    page: style.imageComponent,
    component: style.imagePage,
}

const LoadingSpinner = ({ wrapperClassName, type }) => (
    <span className={cn(TYPE_TO_CLASS[type], wrapperClassName)}>
        <img
            src={spinner}
            alt="spinner"
            className={cn(TYPE_TO_IMG_CLASS[type])}
        />
    </span>
)

LoadingSpinner.propTypes = {
    wrapperClassName: PropTypes.string,
    type: PropTypes.oneOf(['component', 'page']),
}

LoadingSpinner.defaultProps = {
    wrapperClassName: '',
    type: 'component',
}

export default LoadingSpinner
