import jsCookie from 'js-cookie'
import jwtDecode from 'jwt-decode'
import { COOKIE_NAMES, SESSION_STORAGE_KEYS } from '../../../enums'
import LOCAL_STORAGE_KEYS from '../../../enums/local-storage-keys.enum'
import LocalStorage from '../../../utils/localStorage'
import SessionStorage from '../../../utils/sessionStorage'

export const setCookiesToken = (payload, realm) => {
    let COOKIE_EXP
    const accessTokenJwtPayload =
        payload.access_token && jwtDecode(payload.access_token)
    const idTokenJwtPayload = payload.id_token && jwtDecode(payload.id_token)

    if (accessTokenJwtPayload) {
        COOKIE_EXP = new Date(accessTokenJwtPayload.exp * 1000 - 1000)
        jsCookie.set(COOKIE_NAMES.OAUTH2_ACCESS_TOKEN, payload.access_token, {
            expires: COOKIE_EXP,
            path: '/',
        })
    }
    if (idTokenJwtPayload) {
        jsCookie.set(COOKIE_NAMES.OAUTH2_ID_TOKEN, payload.id_token, {
            expires: COOKIE_EXP,
            path: '/',
        })
    }

    if (payload.refresh_token) {
        jsCookie.set(COOKIE_NAMES.OAUTH2_REFRESH_TOKEN, payload.refresh_token, {
            expires: 36500, // 100 years
            path: '/',
        })
    }
    if (realm) {
        jsCookie.set(COOKIE_NAMES.OAUTH2_REALM, realm, {
            expires: 36500, // 100 years
            path: '/',
        })
    }
}

const removeOauthCookies = () => {
    jsCookie.remove(COOKIE_NAMES.OAUTH2_ACCESS_TOKEN)
    jsCookie.remove(COOKIE_NAMES.OAUTH2_REFRESH_TOKEN)
    jsCookie.remove(COOKIE_NAMES.OAUTH2_ID_TOKEN)
    jsCookie.remove(COOKIE_NAMES.OAUTH2_REALM)
}

export const clearLoginData = () => {
    LocalStorage.remove(LOCAL_STORAGE_KEYS.SIGNUP_IS_TRIAL)
    LocalStorage.remove(LOCAL_STORAGE_KEYS.USER_LOGGEDIN)
    LocalStorage.remove(LOCAL_STORAGE_KEYS.SIGNUP_ACTION)

    jsCookie.remove(COOKIE_NAMES.INVITEE_TOKEN)
    jsCookie.remove(COOKIE_NAMES.INVITEE)
    removeOauthCookies()
}

export const clearSignupData = () => {
    jsCookie.remove(COOKIE_NAMES.INVITEE_TOKEN)
    jsCookie.remove(COOKIE_NAMES.INVITEE)
    LocalStorage.remove(LOCAL_STORAGE_KEYS.SIGNUP_IS_TRIAL)
    LocalStorage.remove(LOCAL_STORAGE_KEYS.SELECTED_PRODUCT)
    LocalStorage.remove(LOCAL_STORAGE_KEYS.SIGNUP_ACTION)
    LocalStorage.remove(LOCAL_STORAGE_KEYS.SIGNUP_ZANOX)
    SessionStorage.remove(SESSION_STORAGE_KEYS.SIGNUP_VOUCHER)
}
