import React from 'react'
import { SchemaOf } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
    FormProvider,
    FieldValues,
    useForm,
    SubmitHandler,
    DefaultValues,
    UseFormProps,
    UseFormReturn,
    UseFormSetError,
    UseFormClearErrors,
    UseFormSetValue,
} from 'react-hook-form'

export interface FormProps<TFormValues extends FieldValues>
    extends UseFormProps<TFormValues> {
    className?: string
    validationSchema: SchemaOf<TFormValues>
    defaultValues: DefaultValues<TFormValues>
    onSubmit?: (
        data: TFormValues,
        formFunctions: {
            setError: UseFormSetError<TFormValues>
            clearErrors: UseFormClearErrors<TFormValues>
            setValue: UseFormSetValue<TFormValues>
        },
        event?: React.BaseSyntheticEvent
    ) => unknown | Promise<unknown>
    children?:
        | React.ReactNode
        | ((methods: UseFormReturn<TFormValues>) => React.ReactNode)
    formProps?: React.ComponentPropsWithoutRef<'form'>
}

const Form = <TFormValues extends FieldValues>({
    children,
    className,
    defaultValues,
    validationSchema,
    onSubmit = () => {},
    formProps = {},
    ...restProps
}: FormProps<TFormValues>) => {
    /**
     * Here we type formFunction to "any" and the props to "never" as a
     * performance optimization for packing the package, otherwise typescript
     * throws the error : type instantiation is excessively deep and possibly infinite.
     *
     * This should be fixed in the upcoming V8
     *
     *  https://github.com/react-hook-form/react-hook-form/issues/6679
     */
    const formFunctions: any = useForm<TFormValues>({
        ...restProps,
        resolver: yupResolver(validationSchema),
        defaultValues,
    } as never)

    const handleSubmit: SubmitHandler<TFormValues> = (values, event) => {
        onSubmit(
            values,
            {
                setError: formFunctions.setError,
                clearErrors: formFunctions.clearErrors,
                setValue: formFunctions.setValue,
            },
            event
        )
    }

    return (
        <FormProvider {...formFunctions}>
            <form
                className={className}
                {...formProps}
                onSubmit={formFunctions.handleSubmit(handleSubmit)}
            >
                {typeof children === 'function'
                    ? children(formFunctions)
                    : children}
            </form>
        </FormProvider>
    )
}

export default Form
