import React from 'react'
import cn from 'classnames'
import { useIntl } from 'react-intl'

import Icon from 'components/icon'
import Typography from 'typography'
import { ItemExtendedProps, ItemGroupVariants } from '../../types'

import style from './item.module.scss'

const Item = ({ className, icon, title, subtitle, isSelected, variant, value, onClick, disabled }: ItemExtendedProps) => {
    const intl = useIntl()

    const formattedTitle = intl.formatMessage({ id: title })

    return (
        <div
            onClick={() => !disabled && onClick(value)}
            className={cn(
                style.itemContainer,
                className,
                {
                    [style.tile]: variant === ItemGroupVariants.tile,
                    [style.selected]: isSelected,
                    [style.disabled]: disabled,
                })}
            role="option"
            aria-selected={!!isSelected}
            aria-label={formattedTitle}
        >
            {icon &&
                <section className={style.iconContainer}>
                    <Icon
                        data={icon}
                        className={cn(style.icon, {
                            [style.selected]: isSelected,
                            [style.disabled]: disabled,
                        })}
                    />
                </section>
            }

            <section>
                <Typography
                    as="header"
                    variant="body2"
                    color={(disabled && 'gray') || (isSelected ? 'inverted' : 'main')}
                >
                    {formattedTitle}
                </Typography>

                {subtitle && variant !== ItemGroupVariants.tile &&
                    <Typography
                        className={cn(style.subtitle, { [style.selected]: isSelected })}
                        as="p"
                        variant="caption"
                        color={(disabled && 'gray') || (isSelected ? 'inverted' : 'gray')}
                    >
                        {intl.formatMessage({ id: subtitle })}
                    </Typography>
                }
            </section>
        </div>
    )
}

export default Item