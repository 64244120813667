import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import style from './usp.module.scss'

const renderUsp = (renderImage, { headline, body, image }, color) => (
    <div key={headline} className={cn(style.usp, 'col-xs-12', 'col-md')}>
        <div className="usp">
            <div className={cn(style.uspImage, 'usp__hd')}>
                {
                    renderImage(image)
                }
            </div>
            <div
                className={cn(style.uspHeader, 'usp__bd')}
                style={{
                    backgroundColor: color
                }}
            >
                {headline}
            </div>
            <div className={cn(style.uspBody, 'usp__ft')}>
                {body}
            </div>
        </div>
    </div>
)

const Usp = ({
    renderImage, usps, color
}) => (
    <div className={cn(style.uspWrapper)}>
        <div className="wrapper">
            <div className={cn('row', 'usp-list', style.uspList)}>
                {
                    usps
                        && usps.map(usp => renderUsp(renderImage, usp, color))
                }
            </div>
        </div>
    </div>
)

Usp.propTypes = {
    /**
     * Image component
     */
    renderImage: PropTypes.func.isRequired,
    /**
     * List of the USPs to be rendered
     */
    usps: PropTypes.arrayOf(PropTypes.shape({
        headline: PropTypes.string.isRequired,
        body: PropTypes.string.isRequired,
        image: PropTypes.any.isRequired
    })).isRequired,
    /**
     * Color of the header background
     */
    color: PropTypes.string.isRequired,
}

export default Usp
