import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import Input from '../input'

/**
 * Localized Input component
 * translates label, placeholder and error message
 * @param {*} props
 */
const InputLocalized = (props) => {
    const intl = useIntl()
    const { label, placeholder, form, ...rest } = props
    const translatedLabel = label && intl.formatMessage({ id: label })
    const translatedPlaceholder = placeholder && intl.formatMessage({ id: placeholder })
    const translatedError = form.touched[rest.field.name]
        && form.errors[rest.field.name]
        && typeof form.errors[rest.field.name] === 'string'
        && intl
            .formatMessage({ id: form.errors[rest.field.name] })

    return (
        <Input {...{
            ...rest,
            label: translatedLabel,
            placeholder: translatedPlaceholder,
            form: {
                ...form,
                errors: {
                    ...form.errors,
                    [rest.field.name]: translatedError || form.errors[rest.field.name]
                }
            }
        }}
        />
    )
}

InputLocalized.propTypes = {
    /**
    * Field Label
    * pass a FromattedMessageId here
    */
    label: PropTypes.string,
    /**
    * Placeholder
    * pass a FromattedMessageId here
    */
    placeholder: PropTypes.string,
    /**
    * The variant of the Input
    */
    variant: PropTypes.oneOf(['primary', 'secondary']),
    /**
    * The size of the Input
    */
    size: PropTypes.oneOf(['regular', 'small']),
    /**
    * Css class to pass to the wrapping div
    */
    className: PropTypes.string,
    /**
    * Field object passed by the Formik Field component
    * contains { name, value, onChange, onBlur }
    */
    field: PropTypes.object.isRequired,
    /**
     * Form object passed by Formik
     * also containes values, setXXXX, handleXXXX, dirty, isValid, status, etc.
     */
    form: PropTypes.object.isRequired,
    /**
     * Set true to show the valid indicator icon
     */
    withValidIcon: PropTypes.bool
}

InputLocalized.defaultProps = {
    className: null,
    variant: 'primary',
    size: 'regular',
    withValidIcon: false,
    label: '',
    placeholder: ''
}

export default InputLocalized
