import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { usePrevious } from '../../hooks'

const ProgressLine = ({ children, className, currentStep, totalSteps }) => {
    const [newCompleted, setNewCompleted] = useState(0)
    const previousTotalSteps = usePrevious(totalSteps)
    const previousStep = usePrevious(currentStep)

    useEffect(() => {
        // the new value
        const completedFraction = currentStep / totalSteps
        const completed = Math.round(completedFraction * 100)
        // the previous value
        const lastCompleted = Math.round(
            (previousStep * 100) / previousTotalSteps
        )

        if (
            // the number of total steps increased
            currentStep >= previousStep &&
            (completed < lastCompleted || completed < newCompleted)
        ) {
            setNewCompleted(
                lastCompleted > newCompleted
                    ? lastCompleted + (100 - lastCompleted) * completedFraction
                    : newCompleted
            )
        } else {
            setNewCompleted(completed)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStep, totalSteps])

    return (
        <div className={cn('progress-line', className)}>
            <h4 className={`progress-line__title`}>{children}</h4>
            <div className={`progress-line__total`}>
                <div
                    className={`progress-line__done`}
                    style={{
                        width: `${newCompleted}%`,
                    }}
                />
            </div>
        </div>
    )
}

ProgressLine.propTypes = {
    /**
     * Current milestone
     */
    currentStep: PropTypes.number.isRequired,
    /**
     * 100% of the fill
     */
    totalSteps: PropTypes.number.isRequired,
    /**
     * Css class to pass to the wrapping div
     */
    className: PropTypes.string,
    /**
     * This will appear above the line
     */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
}

ProgressLine.defaultProps = {
    className: null,
}

export default ProgressLine
