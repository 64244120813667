import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { FormattedMessage } from 'react-intl'
import cn from 'classnames'
import starSvg from '../../svg/filled-star.svg'
import { Icon } from '../'

import style from './review.module.scss'
/**
 * Generate a row of stars filled based on review value
 * @param {*} value value of the review (how much of the row should be filled)
 * @param {*} maxValue lenght of the star row
 */
const generateStars = (value, maxValue) => (
    <div className={style.starts}>
        {_.range(1, maxValue + 1).map((position) => {
            const difference = position - value
            let fillWidth = 0
            /**
             * If the difference between the current position and value
             * is between 0 and 1 the star is not full
             * The difference is substracted from the full width of the star
             */
            if (difference > 0 && difference < 1) {
                fillWidth = 100 - difference * 100
            }
            /**
             * If the difference is lower than 0 the start is full
             */
            if (difference <= 0) {
                fillWidth = 100
            }

            return (
                <div className={style.starWrapper} key={position}>
                    <Icon
                        className={style.icon}
                        data={starSvg}
                        key={position}
                    />
                    <div className={style.fillWrapper} style={{ width: `${fillWidth}%` }}>
                        <Icon
                            className={cn(style.icon, style.fillIcon)}
                            data={starSvg}
                            key={position}
                        />
                    </div>
                </div>
            )
        })}
    </div>
)

const Review = ({ value = 0, maxValue = 5, title, subtitle, content, className }) => (
    <div className={cn(style.wrapper, className)}>
        {generateStars(value, maxValue)}
        <div className={style.title}>
            <FormattedMessage id={title} />
        </div>
        <div className={style.subtitle}>
            <FormattedMessage id={subtitle} />
        </div>
        <div className={style.content}>
            <FormattedMessage id={content} />
        </div>
    </div>
)

export const renderReviews = reviews => (
    <div className={cn(style.reviewsWrapper)}>
        {reviews.map(review => (
            <Review
                key={review.title}
                className={cn(style.reviewCard, 'col-xs-12 col-sm-4')}
                value={review.value}
                title={review.title}
                subtitle={review.subtitle}
                content={review.content}
            />
        ))}
    </div>
)

Review.propTypes = {
    value: PropTypes.number.isRequired,
    maxValue: PropTypes.number,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
}

Review.defaultProps = {
    maxValue: 5
}

export default Review
