import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const ProgressNumber = ({ min, max, current, ranOut }) => {
    const [number, setNumber] = useState(min)
    const timeout = (ranOut / max) * 1000

    useEffect(() => {
        setTimeout(() => {
            if (number < current) {
                setNumber(number + 1)
            }
        }, timeout)
    })

    return <div>{number}</div>
}

ProgressNumber.propTypes = {
    /**
     * Min number
     */
    min: PropTypes.number,
    /**
     * Max number
     */
    max: PropTypes.number.isRequired,
    /**
     * Time period to go from min to max in sec
     */
    current: PropTypes.number.isRequired,
    /**
     * Time period to go from min to max in sec
     */
    ranOut: PropTypes.number,
}

ProgressNumber.defaultProps = {
    min: 1,
    ranOut: 1,
}

export default ProgressNumber
