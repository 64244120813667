/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import HeaderContext from '../header-context'

/**
 * Mobile Drawer
 */
const MobileDrawer = ({ children, className, openClassName }) => {
    const { menuOpen, setMenuOpen } = useContext(HeaderContext)
    const node = useRef()

    const handleClick = (e) => {
        if (node.current.contains(e.target)) {
            // inside click
            return null
        }

        // outside click
        return setMenuOpen(false)
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClick)

        return () => {
            document.removeEventListener('mousedown', handleClick)
        }
    })

    return (
        <nav
            ref={node}
            className={cn(
                'menu-drawer',
                className,
                menuOpen && 'menu-drawer--visible',
                menuOpen && openClassName,
            )}
        >
            <div className="menu-drawer__menu" onClick={() => setMenuOpen(false)}>
                <ul className="menu-drawer__list">
                    {children}
                </ul>
            </div>
        </nav>
    )
}

MobileDrawer.propTypes = {
    /**
    * Use <MobileDrawerItem/> to wrap nodes used inside MobileDrawer
    */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    className: PropTypes.string,
    openClassName: PropTypes.string
}

MobileDrawer.defaultProps = {
    openClassName: null,
    className: null
}

export default MobileDrawer
