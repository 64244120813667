import React, { useCallback, useEffect, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import cn from 'classnames'
import { Icon } from '../'
import closeSvg from '../../svg/u-close.svg'

import style from './modal.module.scss'


export interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
    onClose?: () => void
    dialogClassName?: string
    ref?: React.Ref<HTMLDivElement>
    containerRef?: React.Ref<HTMLDivElement>
    hideFooter?: boolean
    fullScreen?: boolean
    header?: React.ReactNode
    footer?: React.ReactNode
    persistent?: boolean
    closeOnOuterClick?: boolean
}

/**
 * Modal window
 * Pass a function as children to get access to close method of this modal
 */
const Modal: React.FC<ModalProps> = React.forwardRef(
    (
        {
            children,
            onClose = () => { },
            dialogClassName = '',
            containerRef = null,
            footer,
            header,
            hideFooter = false,
            persistent = false,
            closeOnOuterClick = true,
            ref: refProp = null,
            fullScreen = false,
            ...props
        },
        ref
    ) => {
        const [show, setShow] = useState(true)

        useEffect(() => {
            document.body.style.top = `-${window.scrollY}px`
            document.body.style.position = 'fixed'
            document.body.style.width = '100%'

            function cleanup() {
                const scrollY = document.body.style.top
                document.body.style.position = ''
                document.body.style.top = ''
                document.body.style.width = ''
                window.scrollTo(0, parseInt(scrollY || '0', 10) * -1)
            }

            return cleanup
        })

        const handleClose = useCallback(() => {
            if (!persistent) {
                setShow(false)
            }
        }, [persistent])

        return (
            <div className={cn({ [style.fullScreen]: fullScreen })}>
                <CSSTransition
                    in={show}
                    classNames="fade"
                    appear
                    exit
                    timeout={250}
                >
                    <div className={style.backdrop} />
                </CSSTransition>
                <div className={style.dialogWrapper} onClick={closeOnOuterClick ? handleClose : undefined}>
                    <CSSTransition
                        in={show}
                        classNames={cn(style.transitionWrapper, 'slide')}
                        appear
                        exit
                        timeout={250}
                        onExited={onClose}
                    >
                        <div
                            ref={containerRef}
                            className={cn(dialogClassName)}
                            onClick={event => event.stopPropagation()}
                        >
                            <div className="modal__content" {...props}>
                                <div className="modal__header">
                                    <div>{header}</div>
                                    {
                                        !persistent &&
                                        <div className={style.closeWrapper}>
                                            <Icon
                                                data={closeSvg}
                                                className={style.closeIcon}
                                                onClick={handleClose}
                                            />
                                        </div>
                                    }
                                </div>

                                <div className="modal__scrollable" ref={ref}>
                                    {typeof children === 'function'
                                        ? children(handleClose)
                                        : children}
                                </div>

                                {!hideFooter && (
                                    <div className="modal__footer">
                                        {footer}
                                    </div>
                                )}
                            </div>
                        </div>
                    </CSSTransition>
                </div>
            </div>
        )
    }
)

export default Modal
