import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

/**
 * Navigation
 */
const Navigation = ({ children, className = null }) => (
    <nav className={cn('top-nav', className)}>
        <ul className="top-nav__list">{children}</ul>
    </nav>
)

Navigation.propTypes = {
    /**
     * Use <NavigationItem/> to wrap nodes used inside Navigation
     */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    className: PropTypes.string,
}

export default Navigation
