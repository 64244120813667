import _ from 'lodash'
import { getItemsFromQuality } from './component/quality-item/quality-item'
import MenuButtonFactory from '../../factory/menu-button'

/**
 * This component is dependent on:
 * 1) https://github.com/videojs/videojs-contrib-quality-levels
 * 2) https://github.com/chrisboustead/videojs-hls-quality-selector
 */

/**
 * The first load of this component, doesnt fill items, just the HD button
 *
 * @param {object} param
 */
const createQualitySelector = ({ player, componentName, isControlBar }) => {
    const item = create(player, componentName, null, isControlBar)

    /**
     * Register and add child to player
     */
    player.registerFrameworkComponent(componentName, item)
    player.addPlayerChild(isControlBar, componentName)

    loadQualityElements(player, componentName, isControlBar)

    return item
}

/**
 * Create a MenuButton with all the items as child. If items is empty, return just the icon.
 * It is populated when the qualityLevels is available
 *
 * @param {*} videoFramework
 * @param {*} componentName
 * @param {*} items: List of MenuItems
 */
const create = (player, componentName, items, isControlBar) => {
    const methods = {
        title: player.i18n('quality.header'),
        constructor() {
            /**
             * If no item is present, hide button
             */
            if (_.isEmpty(items)) {
                this.addClass('gym-player__item--hidden')
            }
        },
        createItems() {
            return items
        },
        buildCSSClass() {
            return `gym-player__menu-button gym-player__quality vjs-icon-hd vjs-icon-placeholder`
        },
    }

    return MenuButtonFactory(player, methods)
}

/**
 * Load the qualities of the video and populate on the HD MenuItem (qualitySelector)
 *
 * @param {*} videoFramework
 * @param {*} player
 * @param {*} componentName
 */
const loadQualityElements = (player, componentName, isControlBar) => {
    if (typeof player.qualityLevels !== 'function') {
        return
    }

    const qualityLevels = player.qualityLevels()

    const quality = () => {
        const levels = qualityLevels && qualityLevels.levels_
        if (_.isEmpty(levels)) {
            return
        }

        /**
         * Create MenuItems for this Menu
         */
        const items = getItemsFromQuality(player, qualityLevels)

        /**
         * Get the current position of this control
         */
        const index = _.findIndex(
            player.getOptions('controlBarItems'),
            controlComponent => controlComponent === componentName
        )

        /**
         * Remove old component and add new one, if there are items
         */
        player.controlBar.removeChild(componentName)
        if (!_.isEmpty(items) && items.length > 1) {
            const item = create(player, componentName, items, isControlBar)
            player.registerFrameworkComponent(componentName, item)
            player.addPlayerChild(isControlBar, componentName, {}, index)
        }
    }

    const debouncedQuality = _.debounce(quality, 200)
    qualityLevels.on('addqualitylevel', debouncedQuality)
    qualityLevels.on('sourcechanged', () => debouncedQuality)

    qualityLevels.on('change', event => {
        const callback = player.getOptions(
            'callback.qualitySelector.qualityChanged'
        )
        if (_.isFunction(callback)) {
            callback(qualityLevels, event)
        }
    })
}

export { createQualitySelector }
