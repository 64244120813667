import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

/**
* Footer list item
*/
const FooterListItem = ({ isTitle= false, children, className = '' }) => (
    <li className={cn(className,
        `footer-list__${isTitle ? 'title' : 'item'}`)}
    >
        {children}
    </li>
)

FooterListItem.propTypes = {
    /**
    * Content of footer list items (list title or link)
    */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,

    /**
    * When true, the item is the section title
    */
    isTitle: PropTypes.bool,

    /**
    * CSS className applied to wrapping element
    */
    className: PropTypes.string
}


export default FooterListItem
