import _ from 'lodash'


/**
 * Create a calculator to control the number of repetitions
 */

const getElements = () => [
    ..._.range(1, 10).map(content => ({
        content
    })),
    {
        content: 0
    },
    {
        event: 'erase',
        content: '<i class="gym-player__icons--erase" />'
    },
    {
        event: 'send',
        content: '<i class="gym-player__icons--send" />'
    }
]

const createItems = (func) => {
    const els = getElements()

    return els.map(el => {
        const item = document.createElement('li')
        item.innerHTML = el.content
        item.onclick = () => func(el)

        return item
    })
}

const createHtmlEl = (type, classNames, text = '') => {
    const el = document.createElement(type)
    _.forEach(classNames, className => el.classList.add(className))
    
    if (text) {
        el.innerText = text
    }

    return el
}

const getCalculator = (player, modal, challenge) => {
    const calculatorEl = createHtmlEl('div', ['gym-player__calculator'])

    /**
     * On the left side some information
     */
    const leftEl = createHtmlEl('div', ['gym-player__calculator--left'])
    const totalEl = createHtmlEl('div', ['gym-player__calc-total'], '000')
    leftEl.appendChild(createHtmlEl('div', ['gym-player__calc-name'], _.startCase(_.toLower(challenge.name))))
    leftEl.appendChild(createHtmlEl('div', ['gym-player__calc-question'], player.i18n('calculator.question')))
    leftEl.appendChild(totalEl)

    /**
     * On the right side a Calculator board
     */
    const rightEl = createHtmlEl('div')
    const boardEl = createHtmlEl('ul', ['gym-player__calculator-items'])
    rightEl.appendChild(boardEl)

    calculatorEl.appendChild(leftEl)
    calculatorEl.appendChild(rightEl)

    /**
     * Change value typed by the user
     */
    const totalChange = (el) => {
        let value = totalEl.textContent
        if (el.event === 'send') {
            const callback = player.getOptions('callback.challenge.calculator')

            if (_.isFunction(callback)) {
                callback(challenge.id, parseInt(value))
            }
            modal.close()
        } else {
            if (el.event === 'erase') {
                value = `0${value}`
            } else {
                value = `${parseInt(value)}${el.content}`
            }
            totalEl.innerHTML = value.substr(0, 3).padStart(3, '0')
        }
    }
    const items = createItems(totalChange)
    items.map(item => boardEl.appendChild(item))

    return calculatorEl
}

export {
    getCalculator
}
