import React from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'

const FluidWrapper = ({ className, wrapperClassName, children }) => (
    <div
        className={cn(
            'container-fluid',
            'position--relative',
            wrapperClassName
        )}
    >
        <div className="row">
            <div className={cn(className, 'col')}>{children}</div>
        </div>
    </div>
)

FluidWrapper.defaultProps = {
    className: null,
    wrapperClassName: null,
    children: null,
}

FluidWrapper.propTypes = {
    className: PropTypes.string,
    wrapperClassName: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
}

export default FluidWrapper
