import React from 'react'
import PropTypes from 'prop-types'

/**
 * Nav items displayed on mobile screens
 */
const MobileNavigation = ({ children }) => (
    <nav className="menu-drawer__ctrls">
        <ul className="menu-drawer-actions">
            {children}
        </ul>
    </nav>
)

MobileNavigation.propTypes = {
    /**
    * Use <MobileNavigationItem/> to wrap nodes used inside MobileNavigation
    */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
}

export default MobileNavigation
