import React from 'react'
import cn from 'classnames'

import { TypographyColors, TypographyVariants } from './types'
import style from './typography.module.scss'

export type TypographyProps = {
    className?: string
    as?: React.ElementType
    color?: keyof typeof TypographyColors
    variant?: keyof typeof TypographyVariants
    [prop: string]: any
}

const Typography: React.FC<TypographyProps> = ({
    color = TypographyColors.default,
    children,
    className,
    variant = 'body1',
    as: Element = 'span',
    ...rest
}) => (
    <Element
        className={cn(
            className,
            style[`color--${color}`],
            style[`variant--${variant}`],
        )}
        {...rest}
    >
        {children}
    </Element>
)

export default Typography
