export enum ItemGroupVariants {
    tile = 'tile',
    list = 'list',
}

export type ItemGroupProps = {
    className?: string
    itemClassName?: string
    name: string
    items: ItemProps[]
    variant?: keyof typeof ItemGroupVariants
    multiple?: boolean
    disabled?: boolean
    tileItemXsCol?: number
    tileItemSmCol?: number
    tileItemMdCol?: number
    tileItemLgCol?: number
}

export interface ItemProps {
    icon?: React.FunctionComponent
    title: string
    subtitle?: string
    value: string
}

export interface ItemExtendedProps extends ItemProps {
    className?: string
    variant: keyof typeof ItemGroupVariants
    disabled?: boolean
    isSelected: boolean
    onClick: (value: string) => void
}
