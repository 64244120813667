import React from 'react'
import { useIntl } from 'react-intl'
import DatePicker from '../date-picker'
import { getCurrentDateFormat } from '../date-picker/util/date-picker.util'

const DatePickerLocalized = props => {
    const { i18nValues, dateFormat, label, form, ...args } = props
    const intl = useIntl()
    const placeholders = i18nValues || {
        day: 'day',
        month: 'month',
        year: 'year',
    }
    const translatedPlaceholders =
        placeholders &&
        Object.keys(placeholders).reduce(
            (acc, key) => ({
                ...acc,
                [key]: intl.formatMessage({
                    id: `date-picker.${placeholders[key]}.placeholder`,
                }),
            }),
            {}
        )

    const translatedLabel = label && intl.formatMessage({ id: label })
    const translatedError =
        form.touched[args.field.name] &&
        form.errors[args.field.name] &&
        typeof form.errors[args.field.name] === 'string' &&
        intl.formatMessage({ id: form.errors[args.field.name] })

    return (
        <DatePicker
            dateFormat={dateFormat || getCurrentDateFormat(intl)}
            i18nValues={translatedPlaceholders}
            label={translatedLabel}
            form={{
                ...form,
                errors: {
                    ...form.errors,
                    [args.field.name]:
                        translatedError || form.errors[args.field.name],
                },
            }}
            {...args}
        />
    )
}

DatePickerLocalized.propTypes = DatePicker.propTypes

DatePickerLocalized.defaultProps = {}

export default DatePickerLocalized
