import * as React from 'react'
import classNames from 'classnames'
import { HeadlineTypes, HeadlineWeights, TypographyColors } from '../types'
import style from './headline.module.scss'
import fontStyle from '../typography.module.scss'

export type HeadlineProps = {
    as?: React.ElementType
    className?: string
    color?: keyof typeof TypographyColors
    type?: keyof typeof HeadlineTypes
    weight?: keyof typeof HeadlineWeights
    [prop: string]: any
}

const Headline: React.FC<HeadlineProps> = ({
    children,
    as: Element = 'h1',
    className,
    color = 'default',
    type = 'default',
    weight,
    ...rest
}) => {
    return (
        <Element
            className={classNames(className, style[type.toLowerCase()], {
                [fontStyle['weight--light']]: weight === 'light',
                [fontStyle['weight--regular']]: weight === 'regular',
                [fontStyle['weight--bold']]: weight === 'bold',
                [fontStyle['color--primary']]: color === 'primary',
                [fontStyle['color--inverted']]: color === 'inverted',
                [fontStyle['color--gray']]: color === 'gray',
                [fontStyle['color--main']]: color === 'main',
            })}
            {...rest}
        >
            {children}
        </Element>
    )
}

export default Headline
