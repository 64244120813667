import _ from 'lodash'
import MenuItemFactory from '../../../../factory/menu-item'
import { isIE } from '../../../../adaptive-streaming/adaptive-streaming'

const createSpotify = player => {
    if (isIE(player.getVideoFramework())) {
        return
    }

    /**
     * Open a Spotify player inside a MenuItem
     *
     * @param {*} src: Url of the Spotify library
     */
    const iframeSpotify = src => {
        const div = document.createElement('div')
        let iframe = document.createElement('iframe')
        iframe = _.extend(iframe, {
            src: `https://open.spotify.com/embed?uri=${src}`,
            width: '100%',
            height: '100%',
            frameborder: '0',
            allowtransparency: 'true',
            allow: 'encrypted-media',
        })

        div.appendChild(iframe)
        div.classList.add('gym-player__iframe-container')

        div.onclick = e => {
            e.stopImmediatePropagation()
            e.stopPropagation()
        }

        return div
    }

    /**
     * Add a row with a text
     *
     * @param {*} text
     */
    const header = (text, className) => {
        const div = document.createElement('div')
        div.innerHTML = `<div>${text}</div>`
        div.className = className

        return div
    }

    // const trackDiv = () => {
    //     const div = document.createElement('div')
    //     div.className = 'oi-oi-oi'
    //     div.onclick = (e) => {
    //         e.stopImmediatePropagation()
    //         e.stopPropagation()
    //         div.classList.add('oi-oi-oi-hidden')
    //         div.classList.remove('oi-oi-oi-hidden')
    //     }

    //     return div
    // }

    const playlists = player.getOptions('playlists')
    const spotifyUrl = _.get(playlists, '0.url')
    if (!spotifyUrl) {
        return null
    }

    const Component = MenuItemFactory(player)
    const item = new Component(player, { label: '', selectable: false })

    item.addClass('gym-player__spotify')
    item.getHtmlEl().appendChild(
        header(player.i18n('music-options.spotify'), 'gym-player__menu-title')
    )
    item.getHtmlEl().appendChild(
        header(
            player.i18n('music-options.spotify.title'),
            'gym-player__menu-item gym-player_spotify-description'
        )
    )
    //  item.getHtmlEl().appendChild(trackDiv())
    item.getHtmlEl().appendChild(iframeSpotify(spotifyUrl))

    return item
}

export { createSpotify }
