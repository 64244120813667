import _ from 'lodash'
import MenuItemFactory from '../../../../factory/menu-item'

/**
 * When one quality is chosen, mark it as select in the component, and unselect the others
 *
 * @param {object} clickedItem
 * @param {object} qualityItem
 * @param {array} qualityLevels
 */
const onChooseLanguage = (player, clickedItem, language, menuItems) => {
    menuItems.forEach(item => {
        item.el().classList.remove('vjs-selected')
    })
    clickedItem.el().classList.add('vjs-selected')

    player.setPlayerState({
        audioLanguage: language.substring(0, 2),
    })
}

const createMenuItem = (player, language, selected, menuItems) => {
    const methods = {
        handleClick() {
            /**
             * change video quality and update list marking the selected one
             */
            onChooseLanguage(player, this, language, menuItems)

            /**
             * Trigger outside callback
             */
            const callback = player.getOptions('callback.languageSelector')
            if (_.isFunction(callback)) {
                callback(language)
            }
        },
    }
    const Component = MenuItemFactory(player, methods)
    const componentOptions = {
        label: player.i18n(`language.${language.substring(0, 2)}`),
        selectable: true,
        selected,
    }
    const item = new Component(player, componentOptions)
    item.addClass('gym-player__menu-item')

    menuItems.push(item)

    return item
}

export { createMenuItem }
