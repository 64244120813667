import React from 'react'
import PropTypes from 'prop-types'

const MobileDrawerItem = ({ children }) => (
    <li className="menu-drawer__item">
        {children}
    </li>
)

MobileDrawerItem.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
}

export default MobileDrawerItem
