import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

/**
* Footer section with border-bottom on desktop
*/
const FooterSection = ({ className= '', children }) => (
    <section
        className={cn(
            'content-wrapper footer-section',
            className
        )}
    >
        {children}
    </section>
)

FooterSection.propTypes = {
    /**
    * Use <FooterListGroup/>
    * to structure elements inside FooterSection.
    */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,

    /**
    * CSS className applied to wrapping element
    */
    className: PropTypes.string
}

export default FooterSection
