import _ from 'lodash'
import GoogleAnalytics from '../api/service/tracker'

const EVENT_TYPES = {
    CLICK: 'click',
    BLUR: 'blur',
    CHANGE: 'change',
}

const EVENTS = {
    TRACKING: 'event_tracking',
    TRACKING_V4: 'event_tracking_v4',
    SET_CUSTOM_DIMENSION: 'set_custom_dimension',
    VIRTUAL_PAGEVIEW: 'VirtualPageview',
}

const push = (...data) => {
    data.forEach(({ event, ...restParams }) => {
        if (event !== EVENTS.TRACKING) {
            return GoogleAnalytics.push({
                event,
                ...restParams,
            })
        }

        // We push the same tracking event twice, one for Universal Analytics and the other for Google Analytics 4
        // until UA sunsets by the end of June 2024, then we can remove it completely.
        const { eventCategory, eventAction, eventLabel, eventValue, params = {} } = restParams

        // Push to UA
        GoogleAnalytics.push({
            event,
            eventCategory,
            ...(eventAction && { eventAction }),
            ...(eventLabel && { eventLabel }),
            ...(eventValue && { eventValue }),
        })

        // Push to GA4
        GoogleAnalytics.push({
            event: EVENTS.TRACKING_V4,
            params: {
                category: eventCategory,
                ...(eventAction && { action: eventAction }),
                ...(eventLabel && { label: eventLabel }),
                ...(eventValue && { value: eventValue }),
                ...params,
            },
        })
    })
}

const getDataset = el => {
    while (el) {
        if (_.keys(el.dataset).length > 0) {
            return el.dataset
        }
        el = el.parentNode
    }

    return null
}

const formatTrackEvt = ({ evt, category, action, label, value, ...restParams }) => ({
    event: EVENTS.TRACKING,
    eventCategory: category,
    ...(action && { eventAction: action }),
    ...(label && { eventLabel: label }),
    ...(value && { eventValue: value }),
    params: restParams,
})

const registerTracker = () => {
    const onEvtTriggered = (ev, type) => {
        const dataset = ev && getDataset(ev.target)

        if (dataset && dataset.evt === type) {
            push(formatTrackEvt(dataset))
        }
    }

    const evtList = [EVENT_TYPES.CLICK, EVENT_TYPES.BLUR, EVENT_TYPES.CHANGE]

    // add event listener to document
    const listeners = evtList.reduce((acc, eventType) => {
        const eventListener = event => onEvtTriggered(event, eventType)
        document.addEventListener(eventType, eventListener, true)

        return {
            ...acc,
            [eventType]: eventListener,
        }
    }, {})

    const unregisterListeners = () => {
        Object.entries(listeners).forEach(([eventType, listener]) => {
            document.removeEventListener(eventType, listener, true)
        })
    }

    return unregisterListeners
}

const Tracker = {
    EVENT_TYPES,
    EVENTS,
    push,
    registerTracker,
}

export default Tracker
