const setGradients = ({ player }) => {

    const insertGradient = (className, onClick) => {
        const el = document.createElement('div')
        el.className = className
        if (onClick) {
            el.onclick = onClick;
        }
        player.el().insertBefore(el, player.el().firstChild.nextSibling)
    }

    insertGradient('gym-player__gradient--top')
    insertGradient('gym-player__gradient--bottom')
    insertGradient('gym-player__gradient--overlay gym-player__modal-sensitive', function() {
        player.toggle();
    })
}

export {
    setGradients
}
