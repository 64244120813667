import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

const BASE_CLASS_NAME = 'progress-circle'

const renderCircleSvg = ({ percentage, bgColor, fillColor }) => (
    <svg className="icon" viewBox="0 0 40 40">
        <circle
            className={`${BASE_CLASS_NAME}__circle`}
            stroke={`#${bgColor}`}
            r="15.9154943092"
            cy="20"
            cx="20"
        />
        <circle
            className={`${BASE_CLASS_NAME}__circle ${BASE_CLASS_NAME}__circle--fill`}
            stroke={`#${fillColor}`}
            r="15.9154943092"
            cy="20"
            cx="20"
            strokeDashoffset={100 - percentage}
            transform="rotate(-90, 20, 20)"
        />
    </svg>
)

const ProgressCircle = ({
    children,
    className,
    percentage,
    bgColor,
    fillColor,
    content,
    contentClassName,
    descriptionClassName,
}) => (
    <div className={cn(BASE_CLASS_NAME, className)}>
        <div className={cn(`${BASE_CLASS_NAME}__content`, contentClassName)}>
            {content}
        </div>
        <div className={`${BASE_CLASS_NAME}__filler`}>
            {renderCircleSvg({ percentage, bgColor, fillColor })}
        </div>
        {children && (
            <div
                className={cn(
                    `${BASE_CLASS_NAME}__description`,
                    descriptionClassName
                )}
            >
                {children}
            </div>
        )}
    </div>
)

renderCircleSvg.propTypes = {
    /**
     * Background color
     */
    bgColor: PropTypes.string,
    /**
     * Filling color
     */
    fillColor: PropTypes.string,
    /**
     * Percentage of the fill
     */
    percentage: PropTypes.number,
}

renderCircleSvg.defaultProps = {
    percentage: 0,
    bgColor: '#eee',
    fillColor: '#ff7f66',
}

ProgressCircle.propTypes = {
    /**
     * Background color
     */
    bgColor: PropTypes.string,
    /**
     * Filling color
     */
    fillColor: PropTypes.string,
    /**
     * Percentage of the fill
     */
    percentage: PropTypes.number,
    /**
     * Css class to pass to the wrapping div
     */
    className: PropTypes.string,
    /**
     * Css class to pass to the centered div
     */
    contentClassName: PropTypes.string,
    /**
     * Css class to pass to the div below the circle
     */
    descriptionClassName: PropTypes.string,
    /**
     * This will appear bellow the circle
     */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    /**
     * This will appear inside the circle
     */
    content: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
}

ProgressCircle.defaultProps = {
    percentage: 0,
    bgColor: 'eee',
    fillColor: 'ff7f66',
    className: null,
    contentClassName: null,
    descriptionClassName: null,
    children: null,
}

export default ProgressCircle
