const withTimeout = (promise, timeout) => {
    const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => {
            reject(new Error('Promise timed out'))
        }, timeout)
    })

    return Promise.race([promise, timeoutPromise])
}

const getVariant = async campaignId => {
    if (!campaignId) {
        throw new Error('No VWO campaign ID present')
    }

    return new Promise(resolve => {
        window.VWO = window.VWO || []
        window.VWO.push([
            'onVariationApplied',
            ([, variantCampaignId, id]) => {
                if (variantCampaignId !== campaignId) {
                    return
                }

                const campaign = window._vwo_exp?.[campaignId]
                const name = campaign?.comb_n?.[id]
                const campaignName = campaign?.name

                resolve({
                    id,
                    name,
                    campaignId,
                    campaignName,
                })
            },
        ])
    })
}

const TIMEOUT_MS = 3000

const VWO = {
    getVariant: async campaignId => {
        let experiment = false

        try {
            experiment = await withTimeout(getVariant(campaignId), TIMEOUT_MS)
        } catch (err) {
            console.error(err)
        }

        return experiment
    },
}

export default VWO
