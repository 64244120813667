import _ from 'lodash'

const isAppleDevice = videoFramework => {
    if (videoFramework && videoFramework.browser) {
        const {
            IS_SAFARI,
            IS_IOS,
            IS_IPAD,
            IS_IPHONE,
            IS_IPOD,
        } = videoFramework.browser

        return IS_SAFARI || IS_IOS || IS_IPAD || IS_IPHONE || IS_IPOD
    }

    return false
}

const isIE = videoFramework => {
    if (videoFramework && videoFramework.browser) {
        const { IE_VERSION } = videoFramework.browser

        return !!IE_VERSION
    }

    return false
}

const getSource = (sources, preferredLanguage, preferredCategory) => {
    if (!Array.isArray(sources)) {
        throw new Error(`No source defined`)
    }

    let chosen = sources.filter(
        source =>
            source.language === preferredLanguage &&
            source.category === preferredCategory
    )

    /**
     * Fallback to video with same language
     */
    if (_.isEmpty(chosen)) {
        chosen = sources.filter(source => source.language === preferredLanguage)
    }

    /**
     * Fallback to video with same category
     */
    if (_.isEmpty(chosen)) {
        chosen = sources.filter(source => source.category === preferredCategory)
    }

    /**
     * Fallback to anything
     */
    if (_.isEmpty(chosen)) {
        chosen = sources
    }

    /**
     * Sort with HLS on top
     */
    const ordered = _.orderBy(chosen, ['type'], ['desc'])

    const src = _.map(ordered, source => ({
        src: source.url,
        type: source.type,
    }))

    return src
}

export { getSource, isIE, isAppleDevice }
