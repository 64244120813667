import _ from 'lodash'
import { createMusicEmbed } from './component/embed-music/embed-music'
import { createSpotify } from './component/spotify/spotify'
import { createMusicVolume } from './component/volume/volume'
import MenuButtonFactory from '../../factory/menu-button'

const instantiator = {
    musicEmbed: createMusicEmbed,
    spotify: createSpotify,
    musicVolume: createMusicVolume
}

/**
 * Creates a menu item with the sound options available (spotify, volume and coach volume)
 *
 * @param {*} param
 */
const createAudioOptionSelector = ({ player, componentName, isControlBar }) => {

    const audioOptions = player.getOptions('audioSelector')

    /**
     * Don't create Audio Options button if there is no child
     */
    if (_.isEmpty(audioOptions)) {
        return
    }

    const methods = {
        title: player.i18n('music-options.header'),
        createItems() {
            return audioOptions
                .map(option => instantiator[option] && instantiator[option](player))
                .filter(it => it)
        },
        buildCSSClass() {
            return 'gym-player__menu-button gym-player__audio-options vjs-icon-placeholder vjs-icon-cog'
        }
    }

    const item = MenuButtonFactory(player, methods)

    /**
     * Register and add child to player
     */
    player.registerFrameworkComponent(componentName, item)
    player.addPlayerChild(isControlBar, componentName)
}

export {
    createAudioOptionSelector
}
