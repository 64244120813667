import React from 'react'
import cn from 'classnames'
import Icon from '../icon/icon'

import infoIcon from '../../svg/al-error.svg'
import warningIcon from '../../svg/al-warning.svg'
import successIcon from '../../svg/al-success.svg'

export enum SEVERITY {
    error = 'error',
    info = 'info',
    warning = 'warning',
    success = 'success',
}

export interface AlertProps extends React.HTMLAttributes<HTMLDivElement> {
    severity?: keyof typeof SEVERITY
    className?: string
}

const Alert: React.FC<AlertProps> = ({
    severity = 'error',
    className,
    children,
    ...props
}) => {
    const icons = {
        error: infoIcon,
        warning: warningIcon,
        info: infoIcon,
        success: successIcon,
    }

    return (
        <div
            className={cn('alert', severity && `alert--${severity}`, className)}
            {...props}
        >
            {icons[severity] && (
                <div className="alert__icon">
                    <Icon data={icons[severity]} className="alert__svg" />
                </div>
            )}
            {children}
        </div>
    )
}

export default Alert
