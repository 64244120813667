import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import InputNote from '../common/InputNote'
import InputLabel from '../common/InputLabel'
import Dropdown from '../dropdown'

import {
    generateDays,
    generateMonths,
    generateYears,
} from './util/date-picker.util'

export const BASE_CLASS_NAME = 'date-picker'
const CONTAINER_CLASS_NAME = `${BASE_CLASS_NAME}-container`
const ERROR_CLASS_NAME = `${BASE_CLASS_NAME}--invalid`

const VALUES = {
    DAY: 'day',
    MONTH: 'month',
    YEAR: 'year',
}

const getDateValues = value => {
    if (value) {
        const date = new Date(value)

        return {
            [VALUES.DAY]: date.getDate(),
            [VALUES.MONTH]: date.getMonth() + 1,
            [VALUES.YEAR]: date.getFullYear(),
        }
    }

    return {}
}

const DatePicker = ({
    className,
    field,
    label,
    variant,
    dateFormat,
    i18nValues,
    form: {
        touched,
        errors,
        setFieldValue,
        initialValues = {},
        setFieldTouched,
    },
    ...rest
}) => {
    const hasError = touched[field.name] && errors[field.name]

    const [values, setValues] = useState(
        getDateValues(field.value || initialValues[field.name])
    )

    const hasDateValues =
        values[VALUES.YEAR] && values[VALUES.MONTH] && values[VALUES.DAY]

    useEffect(() => {
        const date =
            hasDateValues &&
            Date.UTC(
                values[VALUES.YEAR],
                values[VALUES.MONTH] - 1,
                values[VALUES.DAY]
            )

        if (date && date !== field.value) {
            setFieldValue(field.name, date)
            setFieldTouched(field.name, true, false)
        } else if (!date && field.value !== undefined) {
            setFieldValue(field.name, undefined)
            setFieldTouched(field.name, true)
        }
    }, [values, field.name, setFieldValue, field.value, hasDateValues])

    useEffect(() => {
        if (initialValues[field.name]) {
            setValues(getDateValues(initialValues[field.name]))
        }
    }, [field.name, initialValues])

    const generateField = (name, options) => (
        <Dropdown
            key={name}
            variant={variant}
            form={{
                touched: {},
                errors: {},
                initialValues: {},
                setFieldValue: () => {},
            }}
            field={{
                onChange: ev =>
                    setValues({ ...values, [name]: ev.target.value }),
                name,
                value: values[name] || '',
                disabled: rest.disabled,
            }}
            options={options}
            placeholder={(i18nValues && i18nValues[name]) || null}
        />
    )

    const dateFieldsMap = {
        DD: generateField(
            VALUES.DAY,
            generateDays(values[VALUES.YEAR], values[VALUES.MONTH])
        ),
        MM: generateField(VALUES.MONTH, generateMonths()),
        YYYY: generateField(VALUES.YEAR, generateYears()),
    }

    let onBlurTimer

    return (
        <div className={BASE_CLASS_NAME}>
            <div
                className={cn(
                    className,
                    'input-container',
                    hasError && ERROR_CLASS_NAME
                )}
            >
                {label && (
                    <InputLabel className="label" htmlFor={field.name}>
                        {label}
                    </InputLabel>
                )}
                <div
                    className={CONTAINER_CLASS_NAME}
                    onFocus={() => {
                        clearTimeout(onBlurTimer)
                    }}
                    onBlur={() => {
                        onBlurTimer = setTimeout(
                            () => setFieldTouched(field.name, true),
                            20
                        )
                    }}
                >
                    {dateFormat
                        .split('-')
                        .map(datePart => dateFieldsMap[datePart])}
                </div>
                {hasError && <InputNote>{errors[field.name]}</InputNote>}
            </div>
        </div>
    )
}

DatePicker.propTypes = {
    /**
     * Css class to pass to the wrapping div
     */
    className: PropTypes.string,
    /**
     * Label string of the field
     */
    label: PropTypes.string,
    /**
     * Field object passed by the Formik Field component
     * contains { name, value, onChange, onBlur }
     */
    field: PropTypes.object.isRequired,
    /**
     * Form object passed by Formik
     * also containes values, setXXXX, handleXXXX, dirty, isValid, status, etc.
     */
    form: PropTypes.object.isRequired,
    /**
     * The variant of the DatePicker
     */
    variant: PropTypes.oneOf(['primary', 'secondary']),
    /**
     * Format of the date
     */
    dateFormat: PropTypes.oneOf([
        'DD-MM-YYYY',
        'MM-DD-YYYY',
        'YYYY-MM-DD',
        'YYYY-DD-MM',
    ]),
    /**
     * Translations for the component
     */
    i18nValues: PropTypes.object,
}

DatePicker.defaultProps = {
    className: null,
    label: null,
    variant: null,
    dateFormat: 'DD-MM-YYYY',
    i18nValues: null,
}

export default DatePicker
