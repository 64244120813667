import _ from 'lodash'
import ButtonFactory from '../../factory/button'

/**
 * Create a Previous or Next button for the Control Bar
 *
 * @param {boolean} isNext: If the button is "Next" or "Previous" button
 * @param {object} args: Arguments for this button
 */
const createSkipExerciseBtn = (
    isNext,
    { componentName, player, isControlBar }
) => {
    const annotations = player.getOptions('annotations')
    if (_.isEmpty(annotations)) {
        return
        // You need to set "options.annotations" to use "nextExercise" component on "controlBarItems
    }

    /**
     * Name of the icon and the translation key
     */
    const getName = () => (isNext ? 'next' : 'previous')

    /**
     * Check if there is a target Exercise (+1 if click on Next or -1 if click on Previous)
     * and with is the index of the current Exercise
     */
    const getInfo = () => {
        const time =
            player &&
            player.player_ &&
            _.isFunction(player.currentTime) &&
            player.currentTime() * 1000
        const totalExecises = annotations.length
        const currentExerciseIndex = annotations.findIndex(
            annotation => annotation.start <= time && annotation.end >= time
        )
        const hasTarget = isNext
            ? currentExerciseIndex + 1 < totalExecises // next
            : currentExerciseIndex > 0 // previous

        return {
            time,
            hasTarget,
            currentExerciseIndex,
        }
    }

    /**
     * Get Time of previous or next exercise, depending on the isNext variable
     */
    const getTargetExerciseTime = () => {
        const { hasTarget, currentExerciseIndex } = getInfo()
        if (hasTarget) {
            return isNext
                ? annotations[currentExerciseIndex + 1].start / 1000 // next
                : annotations[currentExerciseIndex - 1].start / 1000 // previous
        }

        return isNext
            ? -1 // next default
            : 0 // previous default
    }

    /**
     * Get the text for the Button
     */
    const getText = () => {
        const { time, hasTarget } = getInfo()
        const traslationKey = getName()
        let text = player.i18n(`${traslationKey}-exercise.skip-none`)
        if (hasTarget) {
            // same logic with the mobile team
            const isChallenge = player
                .getOptions('challenges')
                .find(
                    challenge =>
                        time > challenge.historyStart &&
                        time < challenge.feedbackStart
                )
            if (isChallenge) {
                text = player.i18n(`${traslationKey}-exercise.skip-challenge`)
            } else {
                text = player.i18n(`${traslationKey}-exercise.skip-exercise`)
            }
        }

        return text
    }

    const methods = {
        constructor: function () {
            const throttledTextUpdate = _.throttle(() => {
                if (this && this.language) {
                    this.controlText(getText())
                }
            }, 1000)
            player.onTimeUpdate(throttledTextUpdate)
        },
        handleClick: function () {
            // trigger on next/previous pressed
            const time = getTargetExerciseTime()
            if (time >= 0) {
                if (player && _.isFunction(player.currentTime)) {
                    player.currentTime(time)
                }

                // trigger callback
                const callbacks = player.getOptions('callback')
                const callback = callbacks && callbacks[componentName]
                if (_.isFunction(callback)) {
                    callback(time)
                }
            }
        },
        buildCSSClass() {
            return `gym-player__skip--${getName()} vjs-icon-${getName()}-item gym-player__modal-sensitive`
        },
    }

    const item = ButtonFactory(player, methods)

    /**
     * Register and add child to player
     */
    player.registerFrameworkComponent(componentName, item)
    player.addPlayerChild(isControlBar, componentName)
}

export { createSkipExerciseBtn }
