import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { ReactComponent as arrowIcon } from '../../svg/u-next.svg'
import Icon from '../icon/icon'
import { useWindowSize } from '../../hooks'

import style from './carousel.module.scss'

const Carousel = ({ items, renderItem, onChange }) => {
    const [visibleElementIndex, setVisibleElementIndex] = useState(0)
    const slidesRef = items.map(() => React.createRef())
    const { width: widthScreen, height: heightScreen } = useWindowSize()

    const slidesWrapperRef = React.createRef()

    const scrollToElement = index => {
        const el = slidesWrapperRef?.current
        if (slidesRef[index]?.current) {
            el.style.transform = `translateX(-${slidesRef[index].current.offsetLeft}px)`
            setVisibleElementIndex(index)
            onChange(index)
        }
    }

    useEffect(() => {
        scrollToElement(0)
    }, [widthScreen, heightScreen])

    const renderArrow = (isLeft, isVisible) => (
        <Icon
            className={cn(
                style.arrow,
                isLeft ? style.arrowLeft : style.arrowRight,
                !isVisible && style.arrowInvisible
            )}
            data={arrowIcon}
            onClick={() =>
                scrollToElement(visibleElementIndex + (isLeft ? -1 : 1))
            }
        />
    )

    let dragStartLeft = 0
    let dragStartX = 0
    let dragStartY = 0

    const getEventValues = event => {
        const { touches, changedTouches } = event
        const clientX = touches?.[0]?.clientX || changedTouches?.[0]?.clientX
        const clientY = touches?.[0]?.clientY || changedTouches?.[0]?.clientY

        const {
            left,
            width,
        } = slidesWrapperRef?.current.parentElement.getBoundingClientRect()

        return {
            left,
            leftCurrent: slidesWrapperRef?.current.getBoundingClientRect().left,
            clientX,
            value: dragStartLeft + clientX - dragStartX - left,
            width,
            deltaX: dragStartX - clientX,
            deltaY: dragStartY - clientY,
        }
    }

    const startTouch = event => {
        const { width, value, deltaX, deltaY } = getEventValues(event)
        const el = slidesWrapperRef?.current
        el.style.transform = `translateX(${value}px)`

        const xThreshold = 5
        const yThreshold = 30

        const isFirstSlide = visibleElementIndex === 0
        const isLastSlide = visibleElementIndex === items.length - 1

        if (Math.abs(deltaX) > xThreshold && Math.abs(deltaY) < yThreshold) {
            event.preventDefault()
            event.returnValue = false

            return false
        }

        if (
            (deltaX < 0 && isFirstSlide && Math.abs(deltaX) > width / 2) ||
            (deltaX > 0 && isLastSlide && Math.abs(deltaX) > width / 2)
        ) {
            el.style.transition = 'transform 400ms ease'
            window.removeEventListener('touchmove', startTouch, false)

            if (isFirstSlide) {
                scrollToElement(0)
            } else if (isLastSlide) {
                scrollToElement(items.length - 1)
            }

            return false
        }

        return true
    }

    const stopTouch = event => {
        const { value, width, deltaX } = getEventValues(event)

        const el = slidesWrapperRef?.current
        el.style.transition = 'transform 400ms ease'

        const slide = visibleElementIndex
        if (slide === 0 && deltaX < 0) {
            scrollToElement(0)
        } else if (slide === items.length - 1 && deltaX > 0) {
            scrollToElement(items.length - 1)
        } else {
            const swipeDeltaPerc = Math.abs((value / width) % 1)
            const THRESHOLD = 0.3
            if (swipeDeltaPerc > THRESHOLD) {
                const slideCount = deltaX > 0 ? 1 : -1
                scrollToElement(slide + slideCount)
            } else {
                scrollToElement(slide)
            }
        }

        window.removeEventListener('touchmove', startTouch, false)
        window.removeEventListener('touchend', stopTouch, false)
    }

    const onTouchStart = event => {
        const { leftCurrent, clientX, clientY } = getEventValues(event)

        dragStartLeft = leftCurrent
        dragStartX = clientX
        dragStartY = clientY

        const el = slidesWrapperRef?.current
        el.style.transition = ''

        window.addEventListener('touchmove', startTouch, false)
        window.addEventListener('touchend', stopTouch, false)
    }

    return (
        <div className={style.slider}>
            <div className={style.sliderContainer}>
                <div className={style.arrowContainer}>
                    {items.length > 1 &&
                        renderArrow(true, visibleElementIndex > 0)}
                    {items.length > 1 &&
                        renderArrow(
                            false,
                            visibleElementIndex < items.length - 1
                        )}
                </div>
                <div
                    className={style.slides}
                    ref={slidesWrapperRef}
                    onTouchStart={items.length > 1 ? onTouchStart : () => {}}
                >
                    {items.map((item, index) => (
                        <div
                            key={index}
                            className={cn(
                                style.slideItem,
                                items.length === 1 && style.slideItemOnly
                            )}
                            ref={slidesRef[index]}
                        >
                            {renderItem(item, index)}
                        </div>
                    ))}
                </div>
            </div>
            {items.length > 1 && (
                <div className={cn(style.slideDots, style.slideDotsOver)}>
                    {items.map((_, index) => (
                        <div
                            key={`dot-${index}`}
                            className={cn(
                                style.slideDot,
                                index === visibleElementIndex &&
                                    style.slideDotFocused
                            )}
                            onClick={() => scrollToElement(index)}
                        />
                    ))}
                </div>
            )}
        </div>
    )
}

Carousel.defaultProps = {
    onChange: () => undefined,
}

Carousel.propTypes = {
    items: PropTypes.array.isRequired,
    renderItem: PropTypes.func.isRequired,
    onChange: PropTypes.func,
}

export default Carousel
