import _ from 'lodash'

/**
 * Add white points in the progress bar that indicate the Challenges.
 * 
 * This component depends on:
 * https://github.com/spchuang/videojs-markers
 * 
 * @param {*} player 
 */
const setMarkers = ({ player }) => {
    const challenges = player.getOptions('challenges')
    if (_.isEmpty(challenges)) {
        return
    }

    if (_.isFunction(player.markers)) {
        player.markers({
            breakOverlay:{
                display: true
            },
            markers: challenges
                .map(challenge => ({ time: challenge.historyStart / 1000 }))
        })
    }
}
 
export {
    setMarkers
} 
