export enum HeadlineTypes {
    pagetitle = 'pagetitle',
    section = 'section',
    subsection = 'subsection',
    default = 'default',
}

export enum HeadlineWeights {
    light = 'light',
    regular = 'regular',
    bold = 'bold',
}

export enum TextSizes {
    '5xs' = '5xs',
    '4xs' = '4xs',
    '3xs' = '3xs',
    xxs = 'xxs',
    xs = 'xs',
    s = 's',
    m = 'm',
    l = 'l',
    xl = 'xl',
    xxl = 'xxl',
    '3xl' = '3xl',
    '4xl' = '4xl',
}

export enum TextWeights {
    light = 'light',
    regular = 'regular',
    bold = 'bold',
}

export enum TypographyColors {
    default = 'default',
    primary = 'primary',
    inverted = 'inverted',
    gray = 'gray',
    main = 'main',
}

export enum TypographyVariants {
    h1 = 'h1',
    h2 = 'h2',
    h3 = 'h3',
    h4 = 'h4',
    h5 = 'h5',
    h6 = 'h6',
    body1 = 'body1',
    body2 = 'body2',
    button = 'button',
    caption = 'caption',
}
