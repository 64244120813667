import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { FormattedMessage } from 'react-intl'
import { Icon } from '../'

import style from './hero-icon-bar.module.scss'

/**
 * Transform the hex color in rgba and apply the alpha attribute
 */
const hex2rgba = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16))

    return `rgba(${r},${g},${b},${alpha})`
}

const renderItem = (text, values, icon) => (
    <div className={style.bottomBarItem} key={text}>
        <div>
            <Icon data={icon} />
            <span className="label--secondary">
                <FormattedMessage id={text} values={values} />
            </span>
        </div>
    </div>
)

const HeroIconBar = ({ items, color, isXs, cta }) => (
    <div
        className={style.bottomBar}
        style={{
            backgroundColor: hex2rgba(`#${color}`, 0.85),
        }}
    >
        <div className={cn(style.bottomBarWrapper, 'page-wrapper')}>
            <div className={style.bottomBarFirst}>
                {items &&
                    items.map(item =>
                        renderItem(item.text, item.values, item.icon)
                    )}
            </div>
            {!isXs && (
                <div
                    className={style.bottomBarSecond}
                    style={{
                        color: `#${color}`,
                    }}
                >
                    {cta()}
                </div>
            )}
        </div>
    </div>
)

HeroIconBar.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string.isRequired,
            icon: PropTypes.func.isRequired,
            values: PropTypes.object,
        })
    ).isRequired,
    color: PropTypes.string.isRequired,
    isXs: PropTypes.bool.isRequired,
    cta: PropTypes.func.isRequired,
}

export default HeroIconBar
