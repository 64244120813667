import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Icon from '../../../icon'

const socialIconItem = (icon, className='') => {
    const { href, svg, title } = icon

    return (
        <a
            href={href}
            rel="noopener noreferrer"
            target="_blank"
            className={cn('social-icons__link', className)}
            key={href}
            title={title}
        >
            <Icon
                data={svg}
                className="social-icons__icon"
            />
        </a>
    )
}

/**
* Social icons list
*/
const SocialIconList = ({ icons, className }) => (
    <li className={cn('social-icons', className)}>
        {icons.map(icon => socialIconItem(icon))}
    </li>
)

SocialIconList.propTypes = {
    /**
    * Object passed to the <SocialIcon /> component
    * if the list if different from gymondo default
    * should contain { href, svg }
    */
    icons: PropTypes.arrayOf(PropTypes.shape({
        href: PropTypes.node,
        svg: PropTypes.elementType
    })).isRequired,

    /**
    * CSS className applied to the wrapping element
    */
    className: PropTypes.string
}

export default SocialIconList
