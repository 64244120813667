import cn from 'classnames'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import AccordionDetails, { AccordionDetailsProps } from '@mui/material/AccordionDetails'
import AccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordion, { AccordionProps as MuiAccordionProps } from '@mui/material/Accordion'

import Icon from 'components/icon'
import Typography from 'typography';
import { TypographyVariants } from "typography/types";
import { ReactComponent as ArrowIcon } from 'svg/arrow-bold.svg'

import style from './accordion.module.scss'


export interface AccordionProps extends Omit<MuiAccordionProps, 'children'> {
    className?: string,
    summary: string | React.ReactNode,
    details: string | React.ReactNode,
    icon?: React.FC,
    adjacentIcon?: boolean,
    summaryProps?: AccordionSummaryProps,
    detailsProps?: AccordionDetailsProps,
    summaryVariant?: keyof typeof TypographyVariants,
    detailsVariant?: keyof typeof TypographyVariants,
    classes?: {
        expandIcon?: string,
        summaryIcon?: string,
        summaryText?: string,
        detailsText?: string,
    }
}

const Accordion: React.FC<AccordionProps> = ({ className, summary, details, icon, adjacentIcon, summaryProps, detailsProps, summaryVariant = 'button', detailsVariant = 'caption', classes, ...restProps }) => {
    return (
        <MuiAccordion
            className={cn(style.accordion, className)}
            elevation={0}
            disableGutters
            {...restProps}
        >
            <AccordionSummary
                aria-label="accordion summary"
                expandIcon={<Icon className={cn(style.expandIcon, classes?.expandIcon)} data={ArrowIcon} />}
                {...summaryProps}
                className={cn(style.accordionSummary, adjacentIcon && style.adjacentIcon, summaryProps?.className)}
            >
                {icon && <Icon data={icon} className={cn(style.summaryIcon, classes?.summaryIcon)} />}

                <Typography as="span" variant={summaryVariant} color="main" className={cn(style.summaryText, classes?.summaryText)}>
                    {typeof summary === 'string' ? (<FormattedMessage id={summary} />) : summary}
                </Typography>
            </AccordionSummary>

            <AccordionDetails aria-label="accordion text" {...detailsProps} className={cn(style.accordionDetails, detailsProps?.className)}>
                <Typography as="span" variant={detailsVariant} color="main" className={classes?.detailsText}>
                    {typeof details === 'string' ? (<FormattedMessage id={details} />) : details}
                </Typography>
            </AccordionDetails>
        </MuiAccordion>

    )
}

export default Accordion
