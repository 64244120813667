import _ from 'lodash'
import { createSkipExerciseBtn } from './skip-exercise/skip-exercise'
import { createQualitySelector } from './quality-selector/quality-selector'
import { createLanguageSelector } from './language-selector/language-selector'
import { createAudioOptionSelector } from './audio-option/audio-option'
import { setMarkers } from './marker-progress-bar/marker-progress-bar'

import { setAnnotations } from './video-annotation/video-annotation'
import { setExerciseTopBar } from './exercise-top-bar/exercise-top-bar'
import { setCuePoints } from './cue-point/cue-point'
import { setGradients } from './gratient/gradient'

const instantiator = {
    nextExercise: params => createSkipExerciseBtn(true, params),
    previousExercise: params => createSkipExerciseBtn(false, params),
    qualitySelector: createQualitySelector,
    languageSelector: createLanguageSelector,
    audioSelector: createAudioOptionSelector,
    markers: setMarkers,
    annotations: setAnnotations,
    exerciseTopBar: setExerciseTopBar,
    cuePoints: setCuePoints,
    gradients: setGradients,
}

const addPlayerItems = (params) => {
    _.forEach(params.items, item => {
        if (_.isFunction(instantiator[item])) {
            instantiator[item]({...params, componentName: item })
        } else {
            params.player.addPlayerChild(params.isControlBar, item)
        }
    })
}

export {
    addPlayerItems
}