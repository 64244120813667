import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import InputLabel from '../common/InputLabel'
import InputNote from '../common/InputNote'

export const INPUT_VARIANT_CLASSNAME_MAP = {
    primary: `radio--primary`,
    secondary: `radio--secondary`,
}

const Radio = ({
    label,
    className,
    labelClassName,
    variant,
    field,
    form: { touched, errors },
    form,
    options,
    ...rest
}) => {
    const hasError = touched[field.name] && errors[field.name]

    return (
        <div className={cn(className)}>
            {label && <InputLabel htmlFor={field.name}>{label}</InputLabel>}
            <div className="radio-container">
                {options.map(radio => (
                    <div key={`${field.name}-${radio.value}`}>
                        <input
                            className={cn(
                                'radio',
                                INPUT_VARIANT_CLASSNAME_MAP[variant],
                                hasError && 'radio--invalid',
                                field.disabled && 'radio--disabled'
                            )}
                            id={`${field.name}-${radio.value}`}
                            type="radio"
                            checked={field.value === radio.value}
                            {...field}
                            {...rest}
                            name={field.name}
                            value={radio.value}
                        />
                        <InputLabel
                            className={cn(
                                'display--inline-block',
                                labelClassName
                            )}
                            htmlFor={`${field.name}-${radio.value}`}
                        >
                            {radio.label}
                        </InputLabel>
                    </div>
                ))}
            </div>
            {hasError && <InputNote>{errors[field.name]}</InputNote>}
        </div>
    )
}

Radio.propTypes = {
    /**
     * Field Label
     */
    label: PropTypes.string,
    /**
     * The variant of the Input
     */
    variant: PropTypes.oneOf(['primary', 'secondary']),
    /**
     * Css class to pass to the wrapping div
     */
    className: PropTypes.string,
    /**
     * Css class to pass to the label of the input
     */
    labelClassName: PropTypes.string,
    /**
     * Field object passed by the Formik Field component
     * contains { name, value, onChange, onBlur }
     */
    field: PropTypes.object.isRequired,
    /**
     * Form object passed by Formik
     * also containes values, setXXXX, handleXXXX, dirty, isValid, status, etc.
     */
    form: PropTypes.object.isRequired,
    /**
     * Options for the radios
     */
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.node,
            value: PropTypes.node,
        })
    ).isRequired,
}

Radio.defaultProps = {
    label: null,
    className: null,
    labelClassName: null,
    variant: 'primary',
}

export default Radio
