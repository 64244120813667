export default {
    'Play': 'Play',
    'Pause': 'Pause',
    'Fullscreen': 'Fullscreen',
    'Non-Fullscreen': 'Non-Fullscreen',
    'previous-exercise.skip-none': 'To the beginning',
    'previous-exercise.skip-challenge': 'Previous exercise',
    'previous-exercise.skip-exercise': 'Previous exercise',
    'next-exercise.skip-none': ' ',
    'next-exercise.skip-challenge': 'Skip challenge',
    'next-exercise.skip-exercise': 'Skip exercise',
    'quality.header': 'Quality',
    'quality.auto': 'Auto',
    'quality.HD': 'HD',
    'quality.4k': '4K',
    'calculator.question': 'How many repetitions have you done?',
    'language.header': 'Languages',
    'language.de': 'German',
    'language.en': 'English',
    'music-options.volume': 'Coach volume',
    'music-options.spotify': 'Spotify',
    'music-options.spotify.title': 'Change volume in Spotify directly',
    'music-options.music': 'Gymondo Music',
    'music-options.music.music': 'ON',
    'music-options.music.nomusic': 'OFF',
    'music-options.header': 'Sound',
}
