import _ from 'lodash'
import MenuItemFactory from './menu-item'

/**
 * This is a Videojs implementation for a Button.
 *
 * @param {*} player 
 * @param {*} methods 
 */
const MenuButtonFactory = (player, methods = {}) => {
    const MenuButtonComponent = player.getFrameworkComponent('MenuButton')

    /**
     * Ignored for now, the design will review the layout
     */
    const createHeader = (title) => {
        const methods = {}
        const Component = MenuItemFactory(player, methods)
        const componentOptions = {
            label: title,
            selectable: false,
            selected: false
        }
        
        const item = new Component(player, componentOptions)
        item.addClass('gym-player__menu-title')

        return item
    }

    class PlayerMenuButton extends MenuButtonComponent {
        constructor(...params) {
            super(...params)
            if (_.isFunction(methods.constructor)) {
                methods.constructor.call(this, ...params)
            }
            this.controlText(methods.title)
        }

        createItems(...params) {
            if (_.isFunction(methods.createItems)) {
                let items = methods.createItems.call(this, ...params)

                /**
                 * Ignore for now: The design will review the layout
                 */
                if (!_.isEmpty(items)) {
                    const header = createHeader(methods.title)
                    items = [header, ...items]
                }

                return items
            }

            return super.createItems(...params)
        }

        buildCSSClass() {
            if (_.isFunction(methods.buildCSSClass)) {
                return methods.buildCSSClass.call(this)
            }

            return super.buildCSSClass()
        }

        unpressButton() {
            setTimeout(() => {
                super.unpressButton()
            }, 100)
        }
    }

    return PlayerMenuButton
}

export default MenuButtonFactory
