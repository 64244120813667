import _ from 'lodash'
import MenuItemFactory from '../../../../factory/menu-item'
import { LocalStorage, LOCAL_STORAGE_KEYS } from '../../../../../utils/utils'

// list of the items of the menu
let menuItems = []

/**
 * Create items object (4k, HD, 760, ...) for the Quality Selector. For each
 * resolution available, create an item.
 *
 * @param {object} player
 * @param {array} qualityLevels: The available video resolutions
 */
const getItemsFromQuality = (player, qualityLevels) => {
    // Sort alphabetically and prepend an 'Auto' item to the list of qualities
    const levels = [
        {
            name: player.i18n('quality.auto'),
            height: -1,
        },
    ].concat(
        qualityLevels.levels_
            .filter(q => q.height !== undefined)
            .sort((a, b) => b.height - a.height)
    )

    // check if there is a default item that is preselected
    let selectedQuality =
        player.getQuality() ||
        LocalStorage.get(LOCAL_STORAGE_KEYS.VIDEO_QUALITY) ||
        player.getOptions('initial.quality')

    const hasSelectedQuality = levels.find(
        level => level.height === selectedQuality
    )
    if (!hasSelectedQuality) {
        selectedQuality = -1
    }

    setQualityLevel(qualityLevels, selectedQuality)

    // create the items for the menu
    menuItems = _.uniqBy(levels, 'height').map(level =>
        createMenuItem(
            player,
            level,
            selectedQuality === level.height,
            qualityLevels
        )
    )

    return menuItems
}

const setQualityLevel = (qualityLevels, height) => {
    qualityLevels.levels_.forEach(level => {
        level.enabled = level.height === height || height === -1
    })
}

/**
 * When one quality is chosen, mark it as select in the component, and unselect the others
 *
 * @param {object} clickedItem
 * @param {object} qualityItem
 * @param {array} qualityLevels
 */
const onChooseQuality = (clickedItem, player, qualityItem, qualityLevels) => {
    menuItems.forEach(item => {
        item.el().classList.remove('vjs-selected')
    })
    clickedItem.el().classList.add('vjs-selected')
    setQualityLevel(qualityLevels, qualityItem.height)
    player.setQuality(qualityItem.height)

    if (player.currentTime() > 10) {
        player.currentTime(player.currentTime() - 1)
    } else {
        player.currentTime(player.currentTime() + 1)
    }
}

/**
 * Get a frienfly label that represents the video quality. For example,
 * instead of '2160', we show to the user '4k' option
 *
 * @param {object} qualityItem
 */
const getLabel = (player, qualityItem) => {
    const label = {
        1080: player.i18n('quality.HD'),
        2160: player.i18n('quality.4k'),
    }

    return qualityItem.name || label[qualityItem.height] || qualityItem.height
}

/**
 * Create a VideoJs MenuItem with the resolution. On click, triggers the
 * video resolution change and a callback to inform the caller of the library
 *
 * @param {object} player
 * @param {object} qualityItem: The resolution that is transformed in a button
 * @param {boolean} selected: If this resolution is already selected
 * @param {array} qualityLevels
 */
const createMenuItem = (player, qualityItem, selected, qualityLevels) => {
    const methods = {
        handleClick() {
            /**
             * Change video quality and update list marking the selected one
             */
            onChooseQuality(this, player, qualityItem, qualityLevels)

            /**
             * Trigger outside callback
             */
            const callback = player.getOptions(
                'callback.qualitySelector.qualityManualChanged'
            )
            if (_.isFunction(callback)) {
                callback(
                    qualityLevels,
                    qualityItem,
                    getLabel(player, qualityItem)
                )
            }
            if (qualityItem.height) {
                LocalStorage.set(
                    LOCAL_STORAGE_KEYS.VIDEO_QUALITY,
                    qualityItem.height
                )
            }
        },
    }
    const Component = MenuItemFactory(player, methods)

    const componentOptions = {
        label: getLabel(player, qualityItem),
        selectable: true,
        selected,
    }

    const item = new Component(player, componentOptions)
    item.addClass('gym-player__menu-item')

    return item
}

export { getItemsFromQuality }
