import React from 'react'
import cn from 'classnames'
import loadingIcon from '../../svg/loading-icon.svg'
import Icon from 'components/icon'

// variant options
export enum OPTIONS_AS {
    span = 'span',
    button = 'button',
    submit = 'submit',
    reset = 'reset',
}
export enum OPTIONS_VARIANT {
    default = 'default',
    gray = 'gray',
    white = 'white',
}
export enum OPTIONS_SIZE {
    regular = 'regular',
    medium = 'medium',
    big = 'big',
}

// classname options
const BASE_CHIP_CLASS = 'gym-chip'

const SIZE_MODIFIERS_MAP = {
    [OPTIONS_SIZE.medium]: 'gym-chip--medium',
    [OPTIONS_SIZE.big]: 'gym-chip--big',
}

const DISABLED_CLASS_NAME = 'gym-chip--disabled'
const ACTIVE_CLASS_NAME = 'gym-chip--active'
const LOADING_SVG_CLASS_NAME = 'gym-chip__svg--loading'
const SVG_CLASS_NAME = 'gym-chip__svg'
const FLUID_CLASS_NAME = 'gym-chip--fluid'
const CONTENT_CLASS_NAME = 'gym-chip__content'
const CONTENT_CLASS_TRANSFORM = 'gym-chip--text-transform'
const IMAGE_WRAPEPR_CLASS_NAME = 'gym-chip--with-image'
const IMAGE_CLASS_NAME = 'gym-chip__image'
const FLOATING_BORDER_CLASS_NAME = 'gym-chip__border'

export interface ChipProps extends React.HTMLAttributes<HTMLElement> {
    as?: keyof typeof OPTIONS_AS
    variant?: keyof typeof OPTIONS_VARIANT
    size?: keyof typeof OPTIONS_SIZE
    disabled?: boolean
    active?: boolean
    loading?: boolean
    fluid?: boolean
    capitalize?: boolean
    icon?: React.FC
    image?: string
    imageAlt?: string
    className?: string
    children: React.ReactNode
}

/**
 * Chip
 */
const Chip: React.FC<ChipProps> = ({
    as = 'button',
    variant = 'default',
    size = 'regular',
    onClick = () => {},
    className,
    disabled = false,
    active = false,
    loading = false,
    fluid = false,
    capitalize = false,
    children,
    icon,
    image,
    imageAlt,
    ...props
}) => {
    const combinedClassNames = cn(
        BASE_CHIP_CLASS,
        !capitalize && CONTENT_CLASS_TRANSFORM,
        size && size !== OPTIONS_SIZE.regular && SIZE_MODIFIERS_MAP[size],
        variant && `${BASE_CHIP_CLASS}--${variant}`,
        disabled && DISABLED_CLASS_NAME,
        active && ACTIVE_CLASS_NAME,
        fluid && FLUID_CLASS_NAME,
        image && IMAGE_WRAPEPR_CLASS_NAME,
        className
    )

    const ElementType = as === 'span' ? 'span' : 'button'

    return (
        <ElementType
            disabled={disabled}
            className={combinedClassNames}
            type={as !== 'span' ? as : undefined}
            onClick={onClick}
            {...props}
        >
            {image && (
                <div
                    className={cn(IMAGE_CLASS_NAME)}
                    aria-label={imageAlt}
                    style={{
                        backgroundImage: `url(${image})`,
                    }}
                />
            )}
            {active && variant === 'gray' && (
                <div className={cn(FLOATING_BORDER_CLASS_NAME)} />
            )}
            <div className={cn(CONTENT_CLASS_NAME)}>
                {loading && (
                    <Icon
                        data={loadingIcon}
                        className={LOADING_SVG_CLASS_NAME}
                    />
                )}
                {!loading && icon && (
                    <Icon data={icon} className={SVG_CLASS_NAME} />
                )}
                {children}
            </div>
        </ElementType>
    )
}

export default Chip
