import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

/**
* Footer list group, full width on tablet
*/
const FooterListGroup = ({ children, className='' }) => (
    <div className={cn('footer-list__group', className)}>
        {children}
    </div>
)

FooterListGroup.propTypes = {
    /**
    * Use <FooterList/> to wrap nodes used inside FooterListGroup
    */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,

    /**
    * CSS className applied to wrapping element
    */
    className: PropTypes.string
}

export default FooterListGroup
