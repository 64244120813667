import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import InputLabel from '../common/InputLabel'
import InputNote from '../common/InputNote'

export const BASE_CLASS_NAME = 'checkbox'
export const ERROR_CLASS_NAME = `${BASE_CLASS_NAME}--invalid`
export const DISABLED_CLASS_NAME = `${BASE_CLASS_NAME}--disabled`
export const INPUT_VARIANT_CLASSNAME_MAP = {
    primary: `${BASE_CLASS_NAME}--primary`,
    secondary: `${BASE_CLASS_NAME}--secondary`,
    tertiary: `${BASE_CLASS_NAME}--tertiary`
}

/**
 * Custom checkbox
 * to be used with Formik Field component
 * https://jaredpalmer.com/formik/docs/api/field
 */
const Checkbox = ({
    className,
    labelClassName,
    variant,
    field,
    form: { touched, errors },
    label,
    ...rest }) => {
    const hasError = touched[field.name] && errors[field.name]

    return (
        <div className={cn(className, 'checkbox-container')}>
            <input
                className={cn(
                    BASE_CLASS_NAME,
                    INPUT_VARIANT_CLASSNAME_MAP[variant],
                    hasError && ERROR_CLASS_NAME,
                    field.disabled && DISABLED_CLASS_NAME
                )}
                id={field.name}
                type="checkbox"
                checked={field.value}
                {...field}
                {...rest}
            />
            <InputLabel
                className={cn('display--inline-block', labelClassName)}
                htmlFor={field.name}
            >
                {label}
            </InputLabel>
            {hasError && <InputNote>{errors[field.name]}</InputNote>}
        </div>
    )
}

Checkbox.propTypes = {
    /**
    * Field Label
    */
    label: PropTypes.string,
    /**
    * The variant of the Input
    */
    variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
    /**
    * Css class to pass to the wrapping div
    */
    className: PropTypes.string,
    /**
    * Css class to pass to the label of the input
    */
    labelClassName: PropTypes.string,
    /**
    * Field object passed by the Formik Field component
    * contains { name, value, onChange, onBlur }
    */
    field: PropTypes.object.isRequired,
    /**
     * Form object passed by Formik
     * also containes values, setXXXX, handleXXXX, dirty, isValid, status, etc.
     */
    form: PropTypes.object.isRequired
}

Checkbox.defaultProps = {
    className: null,
    labelClassName: null,
    variant: 'primary',
    label: ''
}

export default Checkbox
