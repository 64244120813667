export enum ROLES {
    ROLE_USER_ONBOARDING_NEEDED = 'ROLE_USER_ONBOARDING_NEEDED',
    ROLE_USER_LIMITED = 'ROLE_USER_LIMITED',
    ROLE_USER_INACTIVE = 'ROLE_USER_INACTIVE',
    ROLE_USER_ACTIVE = 'ROLE_USER_ACTIVE',
    ROLE_USER_TRIAL = 'ROLE_USER_TRIAL',
    ROLE_USER_REWARD = 'ROLE_USER_REWARD',
    ROLE_USER_PAUSED = 'ROLE_USER_PAUSED',
    ROLE_USER_EDIT_PAYMENT = 'ROLE_USER_EDIT_PAYMENT',
    ROLE_USER_CHANGE_SUBSCRIPTION = 'ROLE_USER_CHANGE_SUBSCRIPTION',
    ROLE_USER_PARTNER = 'ROLE_USER_PARTNER',
    ROLE_USER_COOP = 'ROLE_USER_COOP',
    ROLE_USER_COOP_SPECIFIC_PRIVACY = 'ROLE_USER_COOP_SPECIFIC_PRIVACY',
    ROLE_USER_COOP_CHANGE_SUBSCRIPTION_OFFER = 'ROLE_USER_COOP_CHANGE_SUBSCRIPTION_OFFER',
    ROLE_USER_PARTNER_SPECIFIC_T_AND_C = 'ROLE_USER_PARTNER_SPECIFIC_T_AND_C',
    ROLE_USER_LIFETIME_SUBSCRIPTION = 'ROLE_USER_LIFETIME_SUBSCRIPTION',
    ROLE_USER_CANCELLED = 'ROLE_USER_CANCELLED',
    ROLE_USER_EXPIRED = 'ROLE_USER_EXPIRED',
    ROLE_USER_CHANGED_SUBSCRIPTION = 'ROLE_USER_CHANGED_SUBSCRIPTION',
    ROLE_USER_EXTERNAL_SIGNUP = 'ROLE_USER_EXTERNAL_SIGNUP',
    ROLE_USER_REGULAR_SUBSCRIPTION = 'ROLE_USER_REGULAR_SUBSCRIPTION',
    ROLE_USER_PAUSE_SUBSCRIPTION = 'ROLE_USER_PAUSE_SUBSCRIPTION',
    ROLE_USER_DISABLED = 'ROLE_USER_DISABLED',
    ROLE_USER_REACTIVATION_ALLOWED = 'ROLE_USER_REACTIVATION_ALLOWED',
    ROLE_USER_CANCELLATION_ALLOWED = 'ROLE_USER_CANCELLATION_ALLOWED',
    ROLE_USER_FREEBIE = 'ROLE_USER_FREEBIE',
    ROLE_USER_SCHEDULED_FOR_DELETION = 'ROLE_USER_SCHEDULED_FOR_DELETION',
}
