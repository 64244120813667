import React, { MouseEventHandler } from 'react'
import cn from 'classnames'
import loadingIcon from '../../svg/loading-icon.svg'
import IconComp from '../icon/icon'

type WrapperElement = {
    className?: string
    type?: 'button' | 'submit' | 'reset'
    onClick?: MouseEventHandler<HTMLButtonElement>
    disabled?: boolean
}
export interface SimpleButtonProps extends WrapperElement {
    as?: React.FC<WrapperElement> | string
    vertical?: boolean
    loading?: boolean
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    iconWrapper?: React.FC
    children: React.ReactNode
}

const BASE_SIMPLE_BUTTON_CLASS = 'simple-btn-gym'
const ICON_CLASS_NAME = 'simple-btn-gym__icon'
const LOADING_SVG_CLASS_NAME = 'simple-btn-gym__icon--loading'
const CONTENT_CLASS_NAME = 'simple-btn-gym__content'
const DISABLED_CLASS_NAME = 'simple-btn-gym--disabled'

const ButtonElement: React.FC<Partial<SimpleButtonProps>> = ({
    children,
    className,
    type = 'button',
    onClick,
    disabled,
}) => (
    <button
        onClick={onClick}
        className={className}
        disabled={disabled}
        type={type}
    >
        {children}
    </button>
)

/**
 * SimpleButton
 */
const SimpleButton: React.FC<SimpleButtonProps> = ({
    vertical,
    className,
    disabled,
    as: Element = ButtonElement,
    icon,
    iconWrapper: Wrapper = 'div',
    loading,
    children,
    ...props
}) => {
    const combinedClassNames = cn(
        BASE_SIMPLE_BUTTON_CLASS,
        vertical && `${BASE_SIMPLE_BUTTON_CLASS}--vertical`,
        disabled && DISABLED_CLASS_NAME,
        className
    )

    return (
        <Element className={combinedClassNames} disabled={disabled} {...props}>
            {loading ? (
                <Wrapper>
                    <IconComp className={cn(ICON_CLASS_NAME, LOADING_SVG_CLASS_NAME)} data={loadingIcon} />
                </Wrapper>
            ) : (
                <Wrapper>
                    <IconComp className={ICON_CLASS_NAME} data={icon} />
                </Wrapper>
            )}
            <div className={CONTENT_CLASS_NAME}>{children}</div>
        </Element>
    )
}

export default SimpleButton
