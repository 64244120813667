import * as React from 'react'
import classNames from 'classnames'
import { TextSizes, TextWeights, TypographyColors } from '../types'
import style from './text.module.scss'
import fontStyle from '../typography.module.scss'

export type TextProps = {
    as?: React.ElementType
    className?: string
    color?: keyof typeof TypographyColors
    size?: keyof typeof TextSizes
    weight?: keyof typeof TextWeights
    [prop: string]: any
}

const Text: React.FC<TextProps> = ({
    children,
    as: Element = 'span',
    className,
    color = 'default',
    size = 'default',
    weight = 'regular',
    ...rest
}) => {
    return (
        <Element
            className={classNames(
                className,
                {
                    [fontStyle['weight--light']]: weight === 'light',
                    [fontStyle['weight--regular']]: weight === 'regular',
                    [fontStyle['weight--bold']]: weight === 'bold',
                    [fontStyle['color--default']]: color === 'default',
                    [fontStyle['color--primary']]: color === 'primary',
                    [fontStyle['color--inverted']]: color === 'inverted',
                    [fontStyle['color--gray']]: color === 'gray',
                    [fontStyle['color--main']]: color === 'main',
                },
                style[`size--${size}`]
            )}
            {...rest}
        >
            {children}
        </Element>
    )
}

export default Text
