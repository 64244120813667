enum PRODUCT_TYPES {
    TRIAL = 'TRIAL',
    FREEBIE = 'FREEBIE',
    ADYEN = 'ADYEN',
    GOOGLE = 'GOOGLE',
    APPLE = 'APPLE',
    AMAZON = 'AMAZON',
    PARTNER = 'PARTNER',
    B2B = 'B2B',
    CORPORATE_HEALTH = 'HEALTH',
}

export default PRODUCT_TYPES
