import _ from 'lodash'
import ButtonFactory from '../../factory/button'

const setExerciseTopBar = ({ player, componentName, isControlBar }) => {
    const annotations = player.getOptions('annotations')
    if (_.isEmpty(annotations)) {
        return {}
    }

    /**
     * Update the top bar progress
     */
    const updateTime = (topBarEl) => {
        if (!topBarEl) {
            return
        }
        
        const time = player && _.isFunction(player.currentTime) && player.currentTime() * 1000
        const annotation = annotations
            .find(annotation => annotation.start <= time && annotation.end >= time)
        if (annotation) {
            const elapsedPer = ((time - annotation.start)
                / (annotation.end - annotation.start)) * 100
            topBarEl.style.width = `${elapsedPer}%`
        }
    }

    const methods = {
        constructor() {
            /**
             * Get the child text element from the button
             */
            const controlText = this.getControlText()
            player.onTimeUpdate(() => updateTime(controlText))
        },
        buildCSSClass() {
            return 'gym-player__top-bar gym-player__modal-sensitive'
        }
    }

    const item = ButtonFactory(player, methods)

    /**
     * Register and add child to player
     */
    player.registerFrameworkComponent(componentName, item)
    player.addPlayerChild(isControlBar, componentName)
}

export {
    setExerciseTopBar
}
