'use client'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

/**
 * Footer column
 * collapse by default on mobile screen
 * expanded on click
 */
const FooterList = ({ children, isExpanded = false, className = '' }) => {
    const [collapsed, setListCollapsed] = useState(true)

    const toggleList = () => setListCollapsed(!collapsed)

    return (
        <ul
            className={cn(
                'footer-list',
                { collapsed: !isExpanded && collapsed },
                className
            )}
            onClick={toggleList}
        >
            {children}
        </ul>
    )
}

FooterList.propTypes = {
    /**
     * Use <FooterListItem/> to wrap nodes used inside FooterList
     */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,

    /**
     * When true the list will not collapse on mobile screens
     */
    isExpanded: PropTypes.bool,

    /**
     * CSS className applied to wrapping element
     */
    className: PropTypes.string,
}

export default FooterList
