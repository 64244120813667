const setHotKeys = ({ player }) => {

    const onKeyPress = e => {
        /**
         * Avoid multiple key press, remove it.
         */
        if (!player.player_) {
            window.removeEventListener('keypress', onKeyPress, false)
            return;
        }

        /**
         * On press spacebar
         */
        if (e.which === 32) {
            player.toggle()
            e.preventDefault()
        }
    }

    // on backspace, starts/stop player
    window.addEventListener('keypress', onKeyPress, false)
}

export {
    setHotKeys
}
