/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Drawer from 'react-drag-drawer'
import {
    disableBodyScroll,
    enableBodyScroll,
    clearAllBodyScrollLocks,
} from 'body-scroll-lock'
import { useWindowSize } from '../../hooks'
import style from './drag-drawer.module.scss'

/**
 * Drag Drawer
 */

const DragDrawer = ({
    className,
    modalClassName,
    children,
    show,
    onClose,
    onOpen,
    onDragClose,
    onParentClick,
    modalDefaultPositionTop,
    ...rest
}) => {
    const [modalRef, setModalRef] = useState(null)
    const [containerRef, setContainerRef] = useState(null)
    const [dragged, setDragged] = useState(false)
    const { height } = useWindowSize()

    const onDragCallback = () => {
        setDragged(true)
    }

    useEffect(() => {
        if (modalRef) {
            const heightDiff = height - modalRef.getBoundingClientRect().height
            const bottomMargin = 30

            modalRef.style.top =
                heightDiff < modalDefaultPositionTop
                    ? `${modalDefaultPositionTop}px`
                    : `${heightDiff + bottomMargin}px`
        }
    }, [modalRef, height, modalDefaultPositionTop])

    useEffect(() => {
        if (typeof window === 'undefined' || !window.addEventListener) {
            return null
        }

        if (containerRef) {
            if (show) {
                disableBodyScroll(containerRef)
            } else {
                enableBodyScroll(containerRef)
            }
        }

        return () => {
            clearAllBodyScrollLocks()
        }
    }, [show, containerRef])

    useEffect(() => {
        if (dragged && !show) {
            onDragClose()
        }
    }, [dragged, show, onDragClose])

    useEffect(() => {
        const handleClick = e => {
            setDragged(false)
            if (modalRef && modalRef.contains(e.target)) {
                return
            }
            onParentClick(e)
        }
        if (show) {
            document.addEventListener('mousedown', handleClick)
        } else {
            document.removeEventListener('mousedown', handleClick)
        }

        return () => {
            document.removeEventListener('mousedown', handleClick)
        }
    }, [modalRef, onParentClick, show, setDragged])

    return (
        <Drawer
            open={show}
            onOpen={onOpen}
            onRequestClose={onClose}
            onDrag={onDragCallback}
            containerElementClass={cn(style.container, className)}
            getContainerRef={setContainerRef}
            getModalRef={setModalRef}
            modalElementClass={cn(style.modal, modalClassName)}
            {...rest}
        >
            <div className={style.swipeBar} />

            {children}
        </Drawer>
    )
}

DragDrawer.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    className: PropTypes.string,
    modalClassName: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onDragClose: PropTypes.func,
    show: PropTypes.bool.isRequired,
    onParentClick: PropTypes.func,
    onOpen: PropTypes.func,
    modalDefaultPositionTop: PropTypes.number,
}

DragDrawer.defaultProps = {
    className: '',
    modalClassName: '',
    onDragClose: () => {},
    onParentClick: () => {},
    onOpen: () => {},
    modalDefaultPositionTop: 50,
}

export default DragDrawer
