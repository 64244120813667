import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import InputNote from '../common/InputNote'
import InputLabel from '../common/InputLabel'

const Slider = ({
    className,
    labelClassName,
    containerClassName,
    label,
    field,
    form: { touched, errors },
    min,
    max,
    step,
    ...rest
}) => {
    const { value, name, disabled } = field

    const hasError = touched[name] && errors[name]

    const stepsRange = max - min
    const numberOfSteps = stepsRange / step + 1

    const percentageFilled =
        typeof value !== 'undefined' ? ((value - min) / stepsRange) * 100 : 0

    return (
        <div
            className={cn(
                'slider',
                containerClassName,
                disabled && 'slider--disabled'
            )}
        >
            {label && (
                <InputLabel
                    htmlFor={name}
                    className={cn('slider-label', labelClassName)}
                >
                    {label}
                </InputLabel>
            )}
            <div className="slider-content">
                <input
                    id={name}
                    type="range"
                    min={min}
                    max={max}
                    step={step}
                    {...field}
                    {...rest}
                    value={typeof value !== 'undefined' ? value : min}
                />
                <div className="slider-division">
                    <div className="bar" />
                    <div
                        className="bar bar-filled"
                        style={{
                            width: `${percentageFilled}%`,
                        }}
                    />
                </div>
                <div className="slider-division">
                    {new Array(numberOfSteps).fill().map((_, index) => (
                        <div
                            key={index}
                            className={cn(
                                'dot',
                                value >= min + index * step && 'dot-filled'
                            )}
                        />
                    ))}
                </div>
            </div>
            {hasError && <InputNote>{errors[name]}</InputNote>}
        </div>
    )
}

Slider.propTypes = {
    /**
     * Css class to pass to the slider
     */
    className: PropTypes.string,
    /**
     * Css class to pass to the label
     */
    labelClassName: PropTypes.string,
    /**
     * Css class to pass to the wrapping div
     */
    containerClassName: PropTypes.string,
    /**
     * Label for slider
     */
    label: PropTypes.string,
    /**
     * Field object passed by the Formik Field component
     * contains { name, value, onChange, onBlur }
     */
    field: PropTypes.object.isRequired,
    /**
     * Form object passed by Formik
     * also contains values, setXXXX, handleXXXX, dirty, isValid, status, etc.
     */
    form: PropTypes.object.isRequired,
    /**
     * Min number of the slider
     */
    min: PropTypes.number,
    /**
     * Max number of the slider
     */
    max: PropTypes.number,
    /**
     * Step
     */
    step: PropTypes.number,
}

Slider.defaultProps = {
    className: null,
    labelClassName: null,
    containerClassName: null,
    label: null,
    min: 0,
    max: 10,
    step: 1,
}

export default Slider
