import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Icon from '../../../icon'

import gymondoLogoBlack from '../../../../svg/logo-black.svg'

const GymLogo = <Icon data={gymondoLogoBlack} className="gym-logo__svg--dark" />

/**
 * Logo for Footer
 */
const FooterLogo = ({ children = null, className = '' }) => {
    // if no children provided wrap default gymondo dark logo
    const logo = children || GymLogo

    return <div className={cn('gym-logo footer-logo', className)}>{logo}</div>
}

FooterLogo.propTypes = {
    /**
     * Component that will be rendered in the Footer
     */
    children: PropTypes.node,

    /**
     * CSS className applied to the wrapping element
     */
    className: PropTypes.string,
}

export default FooterLogo
