import React, { useContext } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'

import HeaderContext from '../header-context'
import Icon from 'components/icon'
import closeSvg from '../../../svg/u-close.svg'
import menuSvg from '../../../svg/u-menu.svg'

const VARIANT_CLASSNAME_MAP = {
    white: '',
    black: 'drawer-btn--black',
    primary: 'drawer-btn--primary',
}

/**
 * Button to open/close the mobile drawer
 */
const DrawerButton = ({ variant, isOpen }) => {
    const { menuOpen, setMenuOpen, fixed } = useContext(HeaderContext)

    return (
        <div
            onClick={() => setMenuOpen(!menuOpen)}
            className={cn(
                'drawer-btn',
                VARIANT_CLASSNAME_MAP[variant],
                fixed && 'drawer-btn--black'
            )}
        >
            <Icon
                data={isOpen ? closeSvg : menuSvg}
                className="drawer-btn__svg"
            />
        </div>
    )
}

DrawerButton.propTypes = {
    variant: PropTypes.string,
    isOpen: PropTypes.bool,
}

DrawerButton.defaultProps = {
    variant: null,
    isOpen: false,
}

export default DrawerButton
